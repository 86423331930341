import _ from "lodash";
import { validate as vali, validators } from "validate-redux-form";

const validate = values => vali(values, {
  username: validators.exists()('This field is required'),
  first_name: validators.exists()('This field is required'),
  last_name: validators.exists()('This field is required'),
  email: validators.exists()('This field is required'),
  password: validators.exists()('This field is required'),
  confirm_password: validators.equals(values.password)('Password does not match'),
  profile: {
    cell_phone: validators.exists()('This field is required'),
  },
});

export default validate;
