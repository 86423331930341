import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import { setMe } from "./login";

const LOADER = 'LOGIN_LOADER';

export const constants = {
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

// ------------------------------------
// Actions
// ------------------------------------

export const update = (data = {}, attachments = []) => (dispatch, getStore) => {
  // Updates the profile associated to the user
  dispatch(setLoader(true));
  api.putAttachments('user/update_me', data, attachments).then((response) => {
    dispatch(setMe(response));
    NotificationManager.success('Data is already updated', 'SUCCESS', 1000);
  }).catch(() => {
    NotificationManager.error('Bad credentials', 'ERROR', 0);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

export const actions = {
  update,
};

export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
};

export const initialState = {
  loader: false,
};

export default handleActions(reducers, initialState);
