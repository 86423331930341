import React, { Component } from 'react';
import {Link, NavLink} from "react-router-dom";

class SideBar extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		const { toggleOpen, navToggle, logOut, user } = this.props;
		return (
			<aside className={`main-sidebar px-0 col-12 col-md-3 col-lg-2 ${toggleOpen ? 'open' : ''}`}>
				<div className="main-navbar">
					<nav className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0 navbar navbar-light">
						<a href="#" className="w-100 mr-0 navbar-brand" >
							<div className="d-table m-auto">
								<img
									id="main-logo"
									className="d-inline-block align-top mr-1"
									src={require('assets/img/connect_logo.jpg')}
									alt="Logo"
								/>
							</div>
						</a>
						<a className="toggle-sidebar d-sm-inline d-md-none d-lg-none" onClick={navToggle}>
							<i className="material-icons"></i>
						</a>
					</nav>
				</div>
				<div className="nav-wrapper">
					<ul className="nav--no-borders flex-column nav">
						<li className="nav-item">
							<NavLink to="/" exact className="nav-link " activeClassName={'active'}>
								<div className="d-inline-block item-icon-wrapper">
									<i className="material-icons">home</i>
								</div>
								<span>Home</span>
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to="/trips" className="nav-link" activeClassName={'active'}>
								<div className="d-inline-block item-icon-wrapper">
									<i className="material-icons">description</i>
								</div>
								<span>Trips</span>
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to="/pending-accounts" className="nav-link" activeClassName={'active'}>
								<div className="d-inline-block item-icon-wrapper">
									<i className="material-icons">assignment</i>
								</div>
								<span>Accounts to verify</span>
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to="/approved-accounts" className="nav-link" activeClassName={'active'}>
								<div className="d-inline-block item-icon-wrapper">
									<i className="material-icons">verified_user</i>
								</div>
								<span>Approved accounts</span>
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to="/denied-accounts" className="nav-link" activeClassName={'active'}>
								<div className="d-inline-block item-icon-wrapper">
									<i className="material-icons">cancel</i>
								</div>
								<span>Denied accounts</span>
							</NavLink>
						</li>
						{
							user.is_superuser &&
							<li className="nav-item">
								<NavLink to="/admin-accounts" className="nav-link" activeClassName={'active'}>
									<div className="d-inline-block item-icon-wrapper">
										<i className="material-icons">perm_identity</i>
									</div>
									<span>Management</span>
								</NavLink>
							</li>
						}
						<li className="nav-item">
							<NavLink to="/trip-reports" className="nav-link" activeClassName={'active'}>
								<div className="d-inline-block item-icon-wrapper">
									<i className="material-icons">vertical_split</i>
								</div>
								<span>Reports</span>
							</NavLink>
						</li>
						<li className="nav-item">
							<Link to="/login" onClick={logOut} className="nav-link">
								<div className="d-inline-block item-icon-wrapper">
									<i className="material-icons">lock</i>
								</div>
								<span>Log Out</span>
							</Link>
						</li>
					</ul>
				</div>
			</aside>
		)
	}
}

export default SideBar;
