import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../Utils/Grid";
import { standardActions } from "../Utils/Grid/StandardActions";

export default class Grids extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    loader: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    listar: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const {
      listar,
    } = this.props;
    listar();
  }

  actions = (cell, row) => {
    const {
      destroy,
    } = this.props;
    if (row.is_superuser) {
      return standardActions({ detail: "/admin-accounts" })(cell, row);
    }
    return standardActions({ detail: "/admin-accounts", erase: destroy })(cell, row);
  }

  render() {
    const { data, loader, page, listar, onSortChange } = this.props;
    return (
      <div className="py-4">
        <h3 className="d-flex justify-content-between align-items-center">
          ADMIN ACCOUNTS
          <Link to="/admin-accounts/create" className="btn btn-primary wmata-buttons-text">
            Create an admin
          </Link>
        </h3>
        <div className="row">
          <div className="mb-4 col-12">
            <div className="mb-4 card card-small">
              <div className="border-bottom card-header"><h6 className="m-0">All admin accounts</h6></div>
              <div className="p-0 px-3 pt-3">
                <Grid
                  hover
                  striped
                  page={page}
                  data={data}
                  loading={loader}
                  onPageChange={listar}
                  onSortChange={onSortChange}
                >
                  <TableHeaderColumn
                    dataField="username"
                    dataSort
                  >
                    Username
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="first_name"
                    dataSort
                  >
                    First Name
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="last_name"
                    dataSort
                  >
                    Last Name
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataSort
                    dataField="is_superuser"
                    dataFormat={cell => (cell ? "YES" : "NO")}
                  >
                    Superuser
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="id"
                    isKey
                    dataAlign="center"
                    dataSort
                    dataFormat={this.actions}
                  >
                    Actions
                  </TableHeaderColumn>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
