import React, { Component } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import PropTypes from 'prop-types';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import { TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import Grid from "../Utils/Grid";

import "react-datepicker/dist/react-datepicker.css";


import { PAST, UPCOMING, TODAY } from "../../../utility/constants";

const TODAY_TRIPS = "TODAY_TRIPS";
const PAST_TRIPS = "PAST_TRIPS";
const UPCOMING_TRIPS = "UPCOMING_TRIPS";

const customStyles = {
  content: {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '100vh',
    maxWidth              : '100%',
    height                : '90vh',
  }
};

function dateFormat(dataString) {
  try {
    const date = moment(dataString, "YYYY-MM-DDTHH:mm:ss");
    return date.format("MM/DD/YYYY HH:mm");
  } catch (error) {
    return null;
  }
}

function priceFormat(dataString) {
  return `$ ${dataString}`;
}

function addressFormat(cell) {
  return (
    <div
      style={{ fontSize: 12, overflow: "hidden", whiteSpace: "normal", maxHeight: "125px" }}
    >
      {cell}
    </div>
  );
}

function amountTransactionFormat(cell) {
  return (
    <React.Fragment>
      {
        cell.map(item => (
          <div
            key={item.id}
            style={{ color: item.cancelled && "#D50032", textDecoration: item.cancelled && "line-through" }}
          >
            $&nbsp;
            {item.amount}
          </div>
        ))
      }
    </React.Fragment>
  );
}

function transactionsFormat(cell) {
  return (
    <React.Fragment>
      {
        cell.map(item => (
          <div
            key={item.id}
            style={{ color: item.cancelled && "#D50032", textDecoration: item.cancelled && "line-through" }}
          >
            {item.authorize_transaction_id}
          </div>
        ))
      }
    </React.Fragment>
  );
}

const RenderTable = ({ page, data, loader, onPageChange, openModal, dateSection }) => {
  return (
    <Grid
      hover
      striped
      page={page}
      data={data}
      loading={loader}
      onPageChange={onPageChange}
      onSortChange={null}
      onRowClick={tripInfo => openModal(tripInfo, dateSection)}
      // onRowClick={tripInfo => tripUpdateDate(tripInfo, dateSection)}
    >
      <TableHeaderColumn
        dataField="user"
        dataSort
        width="130"
      >
        WMATA ID
      </TableHeaderColumn>
      <TableHeaderColumn
        isKey
        dataField="trip_order_number"
        dataSort
        width="100"
        tdStyle={(cell, row) => ({ color: row.trip_cancelled && "#D50032", textDecoration: row.trip_cancelled && "line-through" })}
      >
        Livery #
      </TableHeaderColumn>
      <TableHeaderColumn
        dataSort
        dataField="trip_pick_up_location"
        dataFormat={addressFormat}
      >
        Pick-Up
      </TableHeaderColumn>
      <TableHeaderColumn
        dataSort
        dataField="trip_drop_off_location"
        dataFormat={addressFormat}
      >
        Drop-Off
      </TableHeaderColumn>
      <TableHeaderColumn
        dataSort
        dataField="trip_pick_up_date"
        dataFormat={dateFormat}
        tdStyle={{ whiteSpace: "normal" }}
        width="110"
      >
        Date
      </TableHeaderColumn>
      <TableHeaderColumn
        dataFormat={transactionsFormat}
        dataField="trip_transactions"
        dataSort
        width="130"
      >
        Authorize #
      </TableHeaderColumn>
      <TableHeaderColumn
        dataSort
        dataField="trip_transactions"
        headerAlign="left"
        dataAlign="right"
        width="90"
        dataFormat={amountTransactionFormat}
      >
        Price
      </TableHeaderColumn>
      <TableHeaderColumn
        dataSort
        dataField="trip_wmata_subsidy"
        headerAlign="left"
        dataAlign="right"
        width="90"
        dataFormat={priceFormat}
        tdStyle={(cell, row) => ({ color: row.trip_cancelled && "#D50032", textDecoration: row.trip_cancelled && "line-through" })}
      >
        Subsidy
      </TableHeaderColumn>
    </Grid>
  );
};

class TripReports extends Component {
  static propTypes = {
    retrieve: PropTypes.func.isRequired,
    tripLoader: PropTypes.bool.isRequired,
    listPastTrips: PropTypes.func.isRequired,
    listTodayTrips: PropTypes.func.isRequired,
    listUpcomingTrips: PropTypes.func.isRequired,
    todayTripsLoader: PropTypes.bool.isRequired,
    todayTripsPage: PropTypes.number.isRequired,
    todayTripsData: PropTypes.object.isRequired,
    pastTripsLoader: PropTypes.bool.isRequired,
    pastTripsPage: PropTypes.number.isRequired,
    pastTripsData: PropTypes.object.isRequired,
    upcomingTripsLoader: PropTypes.bool.isRequired,
    upcomingTripsPage: PropTypes.number.isRequired,
    upcomingTripsData: PropTypes.object.isRequired,
    setSearchToday: PropTypes.func.isRequired,
    setSearchPast: PropTypes.func.isRequired,
    setSearchUpcoming: PropTypes.func.isRequired,
    tripUpdateDate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedTrip: null,
      newDateTime: new Date(),
      dateSection: null
    };
    moment.locale("en");
  }

  componentWillMount() {
    const {
      listTodayTrips,
    } = this.props;
    listTodayTrips();
    this.setNewDateTime(new Date());
  }

  setNewDateTime(newDate) {
    let now = newDate;
    const module = now.getMinutes() % 15;
    const minutesToAdd = 15 - module;
    if (module) {
      now = moment(now).add(minutesToAdd, 'm').toDate();
    }
    this.setState({ newDateTime: now });
  }

  openModal = (trip, dateSection) => {
    const {
      retrieve,
    } = this.props;
    if (trip) {
      retrieve(trip.id);
      this.setNewDateTime(new Date());
      this.setState({
        dateSection,
        selectedTrip: trip,
      }, () => this.setState({ showModal: true }));
    }
  }

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  }

  paidAmount(transactions) {
    let total = 0;
    for (let index = 0; index < transactions.length; index++) {
      const element = transactions[index];
      if (element.cancelled) {
        continue
      } else {
        total += Number(element.amount);
      }
    }
    return total.toFixed(2)
  }


  render() {
    const {
      tripLoader,
      retrievedTrip,
      listPastTrips,
      listTodayTrips,
      listUpcomingTrips,
      todayTripsLoader,
      todayTripsPage,
      todayTripsData,
      pastTripsLoader,
      pastTripsPage,
      pastTripsData,
      upcomingTripsLoader,
      upcomingTripsPage,
      upcomingTripsData,
      setSearchToday,
      setSearchPast,
      setSearchUpcoming,
      tripUpdateDate,
    } = this.props;

    const {
      showModal,
      dateSection,
      newDateTime,
      selectedTrip,
    } = this.state;
    return (
      <div className="py-4">
        <h2>REPORTS</h2>
        <div className="row">
          <div className="mb-4 col-lg-12">
            <div className="mb-4 card card-small">
              <div className="border-bottom card-header d-flex justify-content-center">
                <h3 className="m-0">TRIP REPORTS</h3>
              </div>
              <div className="p-0 px-3 pt-3">
                <Tabs
                  defaultActiveKey={TODAY_TRIPS}
                  tabBarPosition="top"
                  onChange={(value) => {
                    if (value === TODAY_TRIPS) {
                      listTodayTrips();
                    } else if (value === PAST_TRIPS) {
                      listPastTrips();
                    } else if (value === UPCOMING_TRIPS) {
                      listUpcomingTrips();
                    }
                    return this.callback;
                  }}
                  renderTabBar={() => <ScrollableInkTabBar />}
                  renderTabContent={() => <TabContent />}
                >
                  <TabPane tab="TODAY TRIPS" key={TODAY_TRIPS}>
                    <div className="py-3 px-3">
                      <input
                        placeholder="Search..."
                        type="search"
                        className="form-control mb-3 ml-auto w-50"
                        onChange={el => setSearchToday(el.currentTarget.value)}
                      />
                      <RenderTable
                        page={todayTripsPage}
                        data={todayTripsData}
                        loader={todayTripsLoader}
                        onPageChange={listTodayTrips}
                        openModal={this.openModal}
                        // tripUpdateDate={tripUpdateDate}
                        dateSection={TODAY}
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="PAST TRIPS" key={PAST_TRIPS}>
                    <div className="py-3 px-3">
                      <input
                        placeholder="Search..."
                        type="search"
                        className="form-control mb-3 ml-auto w-50"
                        onChange={el => setSearchPast(el.currentTarget.value)}
                      />
                      <RenderTable
                        page={pastTripsPage}
                        data={pastTripsData}
                        loader={pastTripsLoader}
                        onPageChange={listPastTrips}
                        openModal={this.openModal}
                        // tripUpdateDate={tripUpdateDate}
                        dateSection={PAST}
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="UPCOMING TRIPS" key={UPCOMING_TRIPS}>
                    <div className="py-3 px-3">
                      <input
                        placeholder="Search..."
                        type="search"
                        className="form-control mb-3 ml-auto w-50"
                        onChange={el => setSearchUpcoming(el.currentTarget.value)}
                      />
                      <RenderTable
                        page={upcomingTripsPage}
                        data={upcomingTripsData}
                        loader={upcomingTripsLoader}
                        onPageChange={listUpcomingTrips}
                        openModal={this.openModal}
                        // tripUpdateDate={tripUpdateDate}
                        dateSection={UPCOMING}
                      />
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Modal
            isOpen={showModal}
            // onAfterOpen={afterOpenModal}
            ariaHideApp={false}
            onRequestClose={() => {}}
            style={customStyles}
            contentLabel="EXAMPLE MODAL"
          >
            {
              tripLoader ? (
              <div
                className="d-flex flex-column justify-content-center"
                style={{ height: '100%', padding: '10px 20px 0 20px' }}
              >
                <h2
                  className="mb-4"
                  style={{ textAlign: "center" }}
                >
                  Loading...
                </h2>
              </div>
              ): retrievedTrip ? (
              <div
                className="d-flex flex-column justify-content-between"
                style={{ height: '100%', padding: '10px 20px 0 20px' }}
              >
                <h3
                  className="mb-3"
                  style={{ textDecoration: "underline" }}
                >
                  { retrievedTrip && retrievedTrip.trip_cancelled ? "Trip ID: " + retrievedTrip.trip_order_number +  " (Canceled)" : "Trip ID: " + retrievedTrip.trip_order_number }
                </h3>


                <div
                  className="mb-2 d-flex"
                >
                  <div className="mr-3" style={{ fontWeight: "bold" }}>WMATA ID:</div>
                  <div>{retrievedTrip.user}</div>
                </div>

                <div
                  className="mb-2 d-flex"
                >
                  <div className="mr-3" style={{ fontWeight: "bold" }}>Full name:</div>
                  <div>{retrievedTrip.fullname}</div>
                </div>

                <div
                  className="mb-2 d-flex"
                >
                  <div className="mr-3" style={{ fontWeight: "bold" }}>Pick-up location:</div>
                  <div>{retrievedTrip.trip_pick_up_location}</div>
                </div>

                <div
                  className="mb-2 d-flex"
                >
                  <div className="mr-3" style={{ fontWeight: "bold" }}>Drop-off location:</div>
                  <div>{retrievedTrip.trip_drop_off_location}</div>
                </div>

                <div
                  className="mb-2 d-flex"
                >
                  <div className="mr-3" style={{ fontWeight: "bold" }}>Charges:</div>
                  <div>$ {retrievedTrip.trip_regular_price}</div>
                </div>

                <div
                  className="mb-2 d-flex"
                >
                  <div className="mr-3" style={{ fontWeight: "bold" }}>Subsidy:</div>
                  <div>$ {retrievedTrip.trip_wmata_subsidy}</div>
                </div>

                <div
                  className="mb-2 d-flex"
                >
                  <div className="mr-3" style={{ fontWeight: "bold" }}>Paid amount:</div>
                  <div>$ {this.paidAmount(retrievedTrip.trip_transactions)}</div>
                </div>

                <div
                  className="mb-2 d-flex"
                >
                  <div className="mr-3" style={{ fontWeight: "bold" }}>Card used:</div>
                  <div>{retrievedTrip.card_name.replace(/([A-Z])/g, " $1").replace(/^\s+/g, "")}</div>
                </div>

                <div
                  className="mb-2 d-flex"
                >
                  <div className="mr-3" style={{ fontWeight: "bold" }}>Current Pick-up Date/Time:</div>
                  <div>{retrievedTrip ? " " + moment(retrievedTrip.trip_pick_up_date, "YYYY-MM-DDTHH:mm:ss").format("LLL") : ""}</div>
                </div>

                {
                  retrievedTrip && !retrievedTrip.trip_cancelled ?
                  <React.Fragment>
                    <div
                      className="mb-2 d-flex align-items-center"
                    >
                      <div className="mr-3" style={{ fontWeight: "bold", color: "#D50032", height: "fit-content" }}>Enter a new Pick-up Date/Time:</div>
                      <DatePicker
                          selected={newDateTime}
                          onChange={date => this.setNewDateTime(date)}
                          showTimeSelect
                          timeFormat="h:mm aa"
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy h:mm aa"
                          className="form-control"
                          minDate={new Date()}
                        />
                    </div>
                    <div className="mb-3">
                      <button
                        type="button"
                        onClick={() => { this.closeModal(); tripUpdateDate(retrievedTrip, newDateTime, dateSection); }}
                        className="btn btn-success wmata-buttons-text mr-2"
                      >
                        Update Date/Time
                      </button>
                      <button
                        type="button"
                        onClick={this.closeModal}
                        className="btn btn-primary wmata-buttons-text"
                      >
                        Cancel
                      </button>
                    </div>
                  </React.Fragment>
                  :
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      onClick={this.closeModal}
                      className="btn btn-primary my-3 wmata-buttons-text"
                    >
                      Close
                    </button>
                  </div>
                }
              </div>

              ):(
                <div
                className="d-flex flex-column justify-content-center"
                style={{ height: '100%', padding: '10px 20px 0 20px' }}
              >
                <h2
                  className="mb-3"
                  style={{ textAlign: "center" }}
                >
                  Error
                </h2>
                <button
                  type="button"
                  onClick={this.closeModal}
                  className="btn btn-primary wmata-buttons-text"
                  style={{ marginRight:"25%", marginLeft: "25%" }}
                >
                  Close
                </button>
              </div>
              )
            }
          </Modal>
        </div>
      </div>
    );
  }
}

export default TripReports;
