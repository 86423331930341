import { api } from "api";
import Swal from 'sweetalert2';
import { push } from "react-router-redux";
import { handleActions } from 'redux-actions';

const SUBMIT = 'REGISTER_SUBMIT';
const LOADER = 'REGISTER_LOADER';

export const constants = {
  SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch) => {
  // Makes the request to register an user
  dispatch(setLoader(true));
  api.post('user', data).then(() => {
    Swal.fire({
      title: 'Success!',
      text: 'The account has been created.',
      type: 'success',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK!',
    }).then(() => {
      dispatch(push("/admin-accounts"));
    });
  }).catch(() => {
    Swal.fire({
      title: 'Error!',
      text: 'The account has not been created, please use another username.',
      type: 'error',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK!',
    }).then(() => {
      // dispatch(push("/admin-accounts"));
    });
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

export const logOut = () => (dispatch) => {
  // Drops user's token
  localStorage.removeItem('token');
};


export const actions = {
  onSubmit,
  logOut,
};

export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
};

export const initialState = {
  loader: false,
};

export default handleActions(reducers, initialState);
