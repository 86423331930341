import _ from "lodash";
import { api } from "api";
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { PENDING, APPROVED, DENIED } from "../../../../utility/constants";

const url = 'wmata-user';

const LOADER_WMATA_ACCOUNTS = 'LOADER_WMATA_ACCOUNTS';
const SET_DATA_WMATA_ACCOUNTS = 'SET_DATA_WMATA_ACCOUNTS';
const SET_PAGE_WMATA_ACCOUNTS = 'SET_PAGE_WMATA_ACCOUNTS';
const SET_FILTRO_WMATA_ACCOUNTS = 'SET_FILTRO_WMATA_ACCOUNTS';
const SET_BUSCADOR_WMATA_ACCOUNTS = 'SET_BUSCADOR_WMATA_ACCOUNTS';
const WMATA_RESET_PASSWORD_SUCCESS = "WMATA_RESET_PASSWORD_SUCCESS";
const SET_UPDATE_DATA_WMATA_ACCOUNTS = 'SET_UPDATE_DATA_WMATA_ACCOUNTS';

export const listar = (page = 1) => (dispatch, getStore) => {
  // Gets a page of registered WMATA USERS
  dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: true });
  const store = getStore();
  const search = store.wmata_accounts.buscador;
  const filtro = store.wmata_accounts.filtro_wmata_accounts;
  const params = { page, search };

  if (filtro) {
    params[''] = filtro;
  }
  api.get(`${url}`, params).catch((error) => {
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
    Swal(
      'Error',
      error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
      'error'
    );
  }).then((data) => {
    if (data) {
      dispatch({ type: SET_DATA_WMATA_ACCOUNTS, data });
      dispatch({ type: SET_PAGE_WMATA_ACCOUNTS, page });
    }
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
  });
};

export const list_pending_accounts = (page = 1) => (dispatch, getStore) => {
  // Gets a page of registered pending account status WMATA USERS
  dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: true });
  const store = getStore();
  const search = store.wmata_accounts.buscador;
  const filtro = store.wmata_accounts.filtro_wmata_accounts;
  const account_status = PENDING;
  const params = { page, search, account_status };
  if (filtro) {
    params[''] = filtro;
  }
  api.get(`${url}`, params).catch((error) => {
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
    Swal(
      'Error',
      error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
      'error'
    );
  }).then((data) => {
    if (data) {
      dispatch({ type: SET_DATA_WMATA_ACCOUNTS, data });
      dispatch({ type: SET_PAGE_WMATA_ACCOUNTS, page });
    }
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
  });
};

export const list_approved_accounts = (page = 1) => (dispatch, getStore) => {
  // Gets a page of registered aprroved account status WMATA USERS
  dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: true });
  const store = getStore();
  const search = store.wmata_accounts.buscador;
  const filtro = store.wmata_accounts.filtro_wmata_accounts;
  const account_status = APPROVED;
  let params = { page, search, account_status };
  if (filtro) {
    params[''] = filtro;
  }
  api.get(`${url}`, params).catch((error) => {
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
    Swal(
      'Error',
      error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
      'error'
    );
  }).then((data) => {
    if (data) {
      dispatch({ type: SET_DATA_WMATA_ACCOUNTS, data });
      dispatch({ type: SET_PAGE_WMATA_ACCOUNTS, page });
    }
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
  });
};

export const list_denied_accounts = (page = 1) =>  (dispatch, getStore) => {
  // Gets a page of registered denied account status WMATA USERS
  dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: true });
  const store = getStore();
  const search = store.wmata_accounts.buscador;
  const filtro = store.wmata_accounts.filtro_wmata_accounts;
  const account_status = DENIED;
  const params = { page, search, account_status };
  if (filtro) {
    params[''] = filtro;
  }
  api.get(`${url}`, params).catch((error) => {
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
    Swal(
      'Error',
      error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
      'error'
    );
  }).then((data) => {
    if (data) {
      dispatch({ type: SET_DATA_WMATA_ACCOUNTS, data });
      dispatch({ type: SET_PAGE_WMATA_ACCOUNTS, page });
    }
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
  });
};

export const detail = id => (dispatch, getState) => {
  // Gets a page of registered pending account status WMATA USERS
  dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: true });
  api.get(`${url}/${id}`).catch((error) => {
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
    if (error.statusCode === 404) {
      dispatch(push('/pending-accounts'));
    }
  }).then((data) => {
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
    if (data) {
      dispatch({ type: SET_UPDATE_DATA_WMATA_ACCOUNTS, updateData: data });
    }
  });
};

export const create = () => (dispatch, getStore) => {
  // Creates a WMATA USER
  const formData = getStore().form.wmata_accounts.values;
  dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: true });
  api.post(`${url}`, formData)
    .then((data) => {
      dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
      Swal('Éxito', 'Se ha creado la cuenta.', 'success')
        .then(() => {
          dispatch(push('/pending-accounts'));
        });
    }).catch((error) => {
      dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
      Swal(
        'Error',
        error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
        'error'
      );
    }).finally(() => {
      dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
    });
};

export const update = () => (dispatch, getStore) => {
  // Updates the WMATA USER data, it could be that this is goig to bet used to update the acoount_status_message
  // of the user
  const formData = _.cloneDeep(getStore().form.wmata_accounts.values);
  dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: true });
  api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
      dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
      if (data) {
        Swal(
          'Éxito',
          'Datos editados correctamente.',
          'success'
        ).then(() => {
          dispatch(push('/pending-accounts'));
        });
      }
    })
    .catch((error) => {
      dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
      Swal(
        'Error',
        error.detail || 'Ha ocurrido un error, verifique los datos.',
        'error'
      );
      if (error.statusCode === 404) {
        dispatch(push('/pending-accounts'));
      }
    });
};

export const approve = () => (dispatch, getStore) => {
  // Set the flag in account_status as aprroved to take advantage of subsidy
  Swal.fire({
    title: 'Approve this account?',
    text: 'This action will allow to this user take advantage of subsidy!',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, approve!',
    cancelButtonText: 'No, cancel!',
    reverseButtons: true,
  }).then((result) => {
    if (result.value) {
      const formData = _.cloneDeep(getStore().wmata_accounts.updateData);
      formData.profile.account_status = APPROVED;
      dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: true });
      api.put(`${url}/${formData.id}`, formData)
        .then((data) => {
          dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
          if (data) {
            Swal.fire({
              title: 'Success!',
              text: 'The account has been approved.',
              type: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK!',
            }).then(() => {
              const current_url = window.location.toString();
              if (current_url.includes("pending-accounts")) {
                dispatch(push('/pending-accounts'));
              } else if (current_url.includes("approved-accounts")) {
                dispatch(push('/approved-accounts'));
              } else if (current_url.includes("denied-accounts")) {
                dispatch(push('/denied-accounts'));
              }
            });
          }
        })
        .catch((error) => {
          dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
          Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
          );
          if (error.statusCode === 404) {
            const current_url = window.location.toString();
            if (current_url.includes("pending-accounts")) {
              dispatch(push('/pending-accounts'));
            } else if (current_url.includes("approved-accounts")) {
              dispatch(push('/approved-accounts'));
            } else if (current_url.includes("denied-accounts")) {
              dispatch(push('/denied-accounts'));
            }
          }
        });
    }
  });
};

export const deny = () => (dispatch, getStore) => {
  // Set the flag in account_status as denied to disabled the subsidy option
  Swal.fire({
    title: "You need to enter a justification to deny this account.",
    input: 'text',
    inputAttributes: {
      autocapitalize: 'off'
    },
    confirmButtonColor: '#D50032',
    showCancelButton: true,
    confirmButtonText: 'Deny Account!',
    showLoaderOnConfirm: true,
    preConfirm: (justification) => {
      if (!justification) {
        Swal.showValidationMessage("Message is required.");
      } else {
        const formData = _.cloneDeep(getStore().wmata_accounts.updateData);
        formData.profile.account_status = DENIED;
        formData.profile.account_status_message = justification;
        dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: true });
        api.put(`${url}/${formData.id}`, formData)
          .then((data) => {
            dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
            if (data) {
              Swal.fire({
                title: 'Success!',
                text: 'The account has been denied.',
                type: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK!',
              }).then(() => {
                const current_url = window.location.toString();
                if (current_url.includes("pending-accounts")) {
                  dispatch(push('/pending-accounts'));
                } else if (current_url.includes("approved-accounts")) {
                  dispatch(push('/approved-accounts'));
                } else if (current_url.includes("denied-accounts")) {
                  dispatch(push('/denied-accounts'));
                }
              });
            }
          })
          .catch((error) => {
            dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
            Swal(
              'Error',
              error.detail || 'An error has accoured, verify the data.',
              'error'
            );
            if (error.statusCode === 404) {
              const current_url = window.location.toString();
              if (current_url.includes("pending-accounts")) {
                dispatch(push('/pending-accounts'));
              } else if (current_url.includes("approved-accounts")) {
                dispatch(push('/approved-accounts'));
              } else if (current_url.includes("denied-accounts")) {
                dispatch(push('/denied-accounts'));
              }
            }
          });
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result) => {
  });
};

export const destroy = id => (dispatch, getStore) => {
  // Deletes a WMATA user
  dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: true });
  api.eliminar(`${url}/${id}`).catch((error) => {
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
    Swal('Error', 'No se ha logrado borrar el registro.', 'error')
      .then(() => {
        dispatch(listar(1));
      });
  }).then((data) => {
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
    Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
      .then(() => {
        dispatch(listar(1));
      });
  });
};

export const hardDelete = id => (dispatch, getStore) => {
  // Set the flag in account_status as aprroved to take advantage of subsidy
  Swal.fire({
    title: 'Delete this account?',
    text: 'You are about to completely remove this account from our records, please click confirm to continue.',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    cancelButtonText: 'Cancel',
    reverseButtons: true,
  }).then((result) => {
    if (result.value) {
      const formData = _.cloneDeep(getStore().wmata_accounts.updateData);
      formData.profile.account_status = APPROVED;
      dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: true });
      api.post(`${url}/${id}/deleteSpamAccount`, {})
        .then((data) => {
          dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
          if (data) {
            Swal.fire({
              title: 'Success!',
              text: 'The account has been deleted.',
              type: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK!',
            }).then(() => {
              dispatch(push('/pending-accounts'));
            });
          }
        })
        .catch((error) => {
          dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
          Swal.fire({
            type: 'error',
            title: 'Error',
            text: error.detail || "An error has accourd."
          });
        });
    }
  });
};

export const search = search => (dispatch, getStore) => {
  // Sets a value to search for more specific results
  dispatch({ type: SET_BUSCADOR_WMATA_ACCOUNTS, buscador: search });
  dispatch(listar(1));
};

export const filtro = filtro => (dispatch, getStore) => {
  // Sets a value to filter for more specific results
  if (filtro === 0) {
    filtro = null;
  }
  dispatch({ type: SET_FILTRO_WMATA_ACCOUNTS, filtro_wmata_accounts: filtro });
  dispatch(listar(1));
};

export const resetPassword = formData => (dispatch, getStore) => {
  // Sets a new value in user's password
  dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: true });
  api.post("/user/pass_reset_password", formData).then((data) => {
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
    dispatch({ type: WMATA_RESET_PASSWORD_SUCCESS });
    NotificationManager.success('Your password is already updated.', 'SUCCESS', 1000);
  }).catch((error) => {
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
    NotificationManager.error('Something went wrong.', 'ERROR', 0);
  }).finally(() => {
    dispatch({ type: LOADER_WMATA_ACCOUNTS, loader: false });
  });
};

export const actions = {
  deny,
  detail,
  search,
  filtro,
  create,
  update,
  listar,
  approve,
  destroy,
  hardDelete,
  resetPassword,
  list_denied_accounts,
  list_pending_accounts,
  list_approved_accounts,
};
export const reducer = {
  [LOADER_WMATA_ACCOUNTS]: (state, { loader }) => {
    return { ...state, loader };
  },
  [SET_DATA_WMATA_ACCOUNTS]: (state, { data }) => {
    return { ...state, data };
  },
  [SET_PAGE_WMATA_ACCOUNTS]: (state, { page }) => {
    return { ...state, page };
  },
  [SET_UPDATE_DATA_WMATA_ACCOUNTS]: (state, { updateData }) => {
    return { ...state, updateData };
  },
  [SET_BUSCADOR_WMATA_ACCOUNTS]: (state, { buscador }) => {
    return { ...state, buscador };
  },
  [SET_FILTRO_WMATA_ACCOUNTS]: (state, { filtro_wmata_accounts }) => {
    return { ...state, filtro_wmata_accounts };
  },
  [WMATA_RESET_PASSWORD_SUCCESS]: (state) => {
    return { ...state, resetPasswordSuccess: true };
  },
};

export const initialState = {
  resetPasswordSuccess: false,
  loader: false,
  page: 1,
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  buscador: '',
  filtro_wmata_accounts: null,
  updateData: {},
};
export default handleActions(reducer, initialState);
