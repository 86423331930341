import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ResetPasswordForm from './ResetPasswordForm';
import './reset_password.css';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class ResetPassword extends Component {
  static propTypes = {
    loader: PropTypes.bool.isRequired,
    resetPassword: PropTypes.func.isRequired,
    resetPasswordSuccess: PropTypes.bool.isRequired,
  };

  resetPassword = (data) => {
    const {
      resetPassword,
    } = this.props;
    resetPassword({ ...data, token: this.props.match.params.token });
  }

  render() {
    const { loader, resetPasswordSuccess } = this.props;
    return (
      <div className="d-flex flex-column all-screen justify-content-center">
        <div className="login-wrapper">
          <div className="card card-login col-lg-3 col-md-4 col-11">
            <img
              src={require('assets/img/connect_logo.jpg')}
              alt="Logo"
              className="login-logo"
            />
            <LoadMask loading={loader} light>
              {
                resetPasswordSuccess ? (
                  <div>
                    <h3>Success!</h3>
                    <h5>Your password has been updated. You may try to log in from the mobile app again.</h5>
                  </div>
                ) : (
                  <ResetPasswordForm onSubmit={this.resetPassword} />
                )
              }
            </LoadMask>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
