import { api } from "api";
import Swal from 'sweetalert2';
import { handleActions } from 'redux-actions';
import moment from "moment";
import { PAST, UPCOMING, TODAY } from "../../../utility/constants";

const url = 'trip';

const LOADER_TODAY_TRIPS = 'LOADER_TODAY_TRIPS';
const SET_DATA_TODAY_TRIPS = "SET_DATA_TODAY_TRIPS";
const SET_PAGE_TODAY_TRIPS = "SET_PAGE_TODAY_TRIPS";

const LOADER_PAST_TRIPS = 'LOADER_PAST_TRIPS';
const SET_PAGE_PAST_TRIPS = "SET_PAGE_PAST_TRIPS";
const SET_DATA_PAST_TRIPS = "SET_DATA_PAST_TRIPS";

const LOADER_UPCOMING_TRIPS = 'LOADER_UPCOMING_TRIPS';
const SET_PAGE_UPCOMING_TRIPS = "SET_PAGE_UPCOMING_TRIPS";
const SET_DATA_UPCOMING_TRIPS = "SET_DATA_UPCOMING_TRIPS";

const SET_PAST_TRIPS_SEARCH = "SET_PAST_TRIPS_SEARCH";
const SET_TODAY_TRIPS_SEARCH = "SET_TODAY_TRIPS_SEARCH";
const SET_UPCOMING_TRIPS_SEARCH = "SET_UPCOMING_TRIPS_SEARCH";

const LOADER_RETRIEVE_TRIP = "LOADER_RETRIEVE_TRIP";
const SET_RETRIEVED_TRIP = "SET_RETRIEVED_TRIP";

export const listTodayTrips = (page = 1) => (dispatch, getStore) => {
  // Gets a page of today trips
  dispatch({ type: LOADER_TODAY_TRIPS, loader: true });
  const store = getStore();
  const search = store.tripReports.searchToday;
  const filtro = store.wmata_accounts.filtro_wmata_accounts;
  const params = { page, date: TODAY, search };

  if (filtro) {
    params[''] = filtro;
  }
  api.get(`${url}`, params).catch((error) => {
    Swal(
      'Error',
      error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
      'error'
    );
  }).then((data) => {
    if (data) {
      dispatch({ type: SET_PAGE_TODAY_TRIPS, page });
      dispatch({ type: SET_DATA_TODAY_TRIPS, data });
    }
    dispatch({ type: LOADER_TODAY_TRIPS, loader: false });
  }).catch(() => {
    dispatch({ type: LOADER_TODAY_TRIPS, loader: false });
  });
};

export const listPastTrips = (page = 1) => (dispatch, getStore) => {
  // Gets a page of past trips
  dispatch({ type: LOADER_PAST_TRIPS, loader: true });
  const store = getStore();
  const search = store.tripReports.searchPast;
  const filtro = store.wmata_accounts.filtro_wmata_accounts;
  const params = { page, date: PAST, search };

  if (filtro) {
    params[''] = filtro;
  }
  api.get(`${url}`, params).catch((error) => {
    Swal(
      'Error',
      error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
      'error'
    );
  }).then((data) => {
    if (data) {
      dispatch({ type: SET_PAGE_PAST_TRIPS, page });
      dispatch({ type: SET_DATA_PAST_TRIPS, data });
    }
    dispatch({ type: LOADER_PAST_TRIPS, loader: false });
  }).catch(() => {
    dispatch({ type: LOADER_PAST_TRIPS, loader: false });
  });
};

export const listUpcomingTrips = (page = 1) => (dispatch, getStore) => {
  // Gets a page of upcoming trips
  dispatch({ type: LOADER_UPCOMING_TRIPS, loader: true });
  const store = getStore();
  const search = store.tripReports.searchUpcoming;
  const filtro = store.wmata_accounts.filtro_wmata_accounts;
  const params = { page, date: UPCOMING, search };

  if (filtro) {
    params[''] = filtro;
  }
  api.get(`${url}`, params).catch((error) => {
    Swal(
      'Error',
      error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
      'error'
    );
  }).then((data) => {
    if (data) {
      dispatch({ type: SET_PAGE_UPCOMING_TRIPS, page });
      dispatch({ type: SET_DATA_UPCOMING_TRIPS, data });
    }
    dispatch({ type: LOADER_UPCOMING_TRIPS, loader: false });
  }).catch(() => {
    dispatch({ type: LOADER_UPCOMING_TRIPS, loader: false });
  });
};

export const setSearchToday = search => (dispatch, getStore) => {
  // Setts a value to search in backend of today trips
  dispatch({ type: SET_TODAY_TRIPS_SEARCH, search });
  dispatch(listTodayTrips(1));
};

export const setSearchPast = search => (dispatch, getStore) => {
  // Setts a value to search in backend of past trips
  dispatch({ type: SET_PAST_TRIPS_SEARCH, search });
  dispatch(listPastTrips(1));
};

export const setSearchUpcoming = search => (dispatch, getStore) => {
  // Setts a value to search in backend of upcoming trips
  dispatch({ type: SET_UPCOMING_TRIPS_SEARCH, search });
  dispatch(listUpcomingTrips(1));
};

export const tripUpdateDate = (tripInfo, newDateTime, dateCategory) => (dispatch, getStore) => {

  const store = getStore();

  if (dateCategory === UPCOMING) {
    dispatch({ type: LOADER_UPCOMING_TRIPS, loader: true });
  } else if (dateCategory === TODAY) {
    dispatch({ type: LOADER_TODAY_TRIPS, loader: true });
  } else {
    dispatch({ type: LOADER_PAST_TRIPS, loader: true });
  }
  // console.log(tripInfo.id);
  const newDateTimeString = moment(newDateTime).format("MM/DD/YYYY HH:mm");

  api.post(`/trip/${tripInfo.id}/adminTripUpdate`, { new_date_str: newDateTimeString })
    .then(() => {
      Swal.fire('Success', 'The trip has been updated.', 'success')
        .then(() => {
          if (dateCategory === UPCOMING) {
            dispatch(listUpcomingTrips(store.tripReports.upcomingTripsPage));
          } else if (dateCategory === TODAY) {
            dispatch(listTodayTrips(store.tripReports.todayTripsPage));
          } else {
            dispatch(listPastTrips(store.tripReports.pastTripsPage));
          }
        });
    }).catch((error) => {
      Swal.fire(
        'Error',
        error.detail || 'An error has occurred',
        'error'
      );
    }).finally(() => {
      if (dateCategory === UPCOMING) {
        dispatch({ type: LOADER_UPCOMING_TRIPS, loader: false });
      } else if (dateCategory === TODAY) {
        dispatch({ type: LOADER_TODAY_TRIPS, loader: false });
      } else {
        dispatch({ type: LOADER_PAST_TRIPS, loader: false });
      }
    });
};

export const retrieve = id => (dispatch, getStore) => {
  // Gets the datails of a specific trip
  const params = { };
  dispatch({ type: LOADER_RETRIEVE_TRIP, loader: true });
  dispatch({ type: SET_RETRIEVED_TRIP, retrievedTrip: undefined });

  api.get(`/trip/${id}`, params)
    .then((data) => {
      dispatch({ type: SET_RETRIEVED_TRIP, retrievedTrip: data });
    })
    .catch(() => {
    })
    .finally(() => {
      dispatch({ type: LOADER_RETRIEVE_TRIP, loader: false });
    });
};

export const actions = {
  retrieve,
  listPastTrips,
  listTodayTrips,
  listUpcomingTrips,
  setSearchToday,
  setSearchPast,
  setSearchUpcoming,
  tripUpdateDate,
};

export const reducer = {
  [LOADER_RETRIEVE_TRIP]: (state, { loader }) => {
    return { ...state, tripLoader: loader };
  },
  [SET_RETRIEVED_TRIP]: (state, { retrievedTrip }) => {
    return { ...state, retrievedTrip };
  },
  [LOADER_TODAY_TRIPS]: (state, { loader }) => {
    return { ...state, todayTripsLoader: loader };
  },
  [SET_PAGE_TODAY_TRIPS]: (state, { page }) => {
    return { ...state, todayTripsPage: page };
  },
  [SET_DATA_TODAY_TRIPS]: (state, { data }) => {
    return { ...state, todayTripsData: data };
  },
  [LOADER_PAST_TRIPS]: (state, { loader }) => {
    return { ...state, pastTripsLoader: loader };
  },
  [SET_PAGE_PAST_TRIPS]: (state, { page }) => {
    return { ...state, pastTripsPage: page };
  },
  [SET_DATA_PAST_TRIPS]: (state, { data }) => {
    return { ...state, pastTripsData: data };
  },
  [LOADER_UPCOMING_TRIPS]: (state, { loader }) => {
    return { ...state, upcomingTripsLoader: loader };
  },
  [SET_PAGE_UPCOMING_TRIPS]: (state, { page }) => {
    return { ...state, upcomingTripsPage: page };
  },
  [SET_DATA_UPCOMING_TRIPS]: (state, { data }) => {
    return { ...state, upcomingTripsData: data };
  },
  [SET_TODAY_TRIPS_SEARCH]: (state, { search }) => {
    return { ...state, searchToday: search };
  },
  [SET_PAST_TRIPS_SEARCH]: (state, { search }) => {
    return { ...state, searchPast: search };
  },
  [SET_UPCOMING_TRIPS_SEARCH]: (state, { search }) => {
    return { ...state, searchUpcoming: search };
  },
};

export const initialState = {
  tripLoader: false,
  retrievedTrip: undefined,
  todayTripsLoader: false,
  todayTripsPage: 1,
  todayTripsData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  searchToday: "",
  pastTripsLoader: false,
  pastTripsPage: 1,
  pastTripsData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  searchPast: "",
  upcomingTripsLoader: false,
  upcomingTripsPage: 1,
  upcomingTripsData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  searchUpcoming: "",
  filtro_wmata_: null,
};

export default handleActions(reducer, initialState);
