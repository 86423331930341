import { connect } from 'react-redux';
import { resetPassword } from '../../../../redux/modules/WMATA/accounts/accounts';
import ResetPassword from './ResetPassword';


const ms2p = (state) => {
  return {
    loader: state.wmata_accounts.loader,
    resetPasswordSuccess: state.wmata_accounts.resetPasswordSuccess,
  };
};

const md2p = { resetPassword };

export default connect(ms2p, md2p)(ResetPassword);
