import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/trips/index';
import TripList from './List';


const ms2p = (state) => state.trips;

const md2p = actions;

export default connect(ms2p, md2p)(TripList);
