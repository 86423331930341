import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import RegisterForm from './RegisterForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Registro extends Component {
  static propTypes = {
    loader: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.state = { prueba: true };
  }

  render() {
    const { onSubmit, loader } = this.props;
    if (localStorage.getItem('token')) {
      return (<Redirect to="/" />);
    }
    return (
      <div className="d-flex flex-column all-screen justify-content-center">
        <div className="login-wrapper">
          <div className="card card-login col-lg-3 col-md-4 col-11">
            <h5 className="text-center pv">REGISTER</h5>
            <LoadMask loading={loader} light>
              <RegisterForm onSubmit={onSubmit} />
              <div className="d-flex flex-column align-items-center">
                <span className="mt-2">Have an account?</span>
                <Link className="mt-1" to="/login">Log In</Link>
              </div>
            </LoadMask>
          </div>
        </div>
      </div>
    );
  }
}

export default Registro;
