import React from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from './validation';
import { renderField } from '../../Utils/renderField';

const ResetPasswordForm = (props) => {
  const { handleSubmit } = props;
  return (
    <form name="ResetPasswordForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
      <div className="form-group has-feedback mt-4">
        <label htmlFor="password">New Password</label>
        <Field
          name="password"
          label="Contraseña"
          component={renderField}
          type="password"
          className="form-control"
        />
      </div>
      <div className="form-group has-feedback">
        <label htmlFor="password">Confirm Password</label>
        <Field
          name="confirm_password"
          label="Contraseña"
          component={renderField}
          type="password"
          className="form-control"
        />
      </div>
      <div className="buttons-box">
        <button type="submit" className="btn btn-primary m-1 align-self-center">Submit</button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'wmata_reset_password', // a unique identifier for this form
  validate,
})(ResetPasswordForm);
