import { connect } from 'react-redux';
import { onSubmit } from '../../../redux/modules/cuenta/register';
import AdminForm from './AdminForm';


const ms2p = (state) => {
  return {
    loader: state.register.loader,
  };
};

const md2p = { onSubmit };

export default connect(ms2p, md2p)(AdminForm);
