import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const SUBMIT = 'LOGIN_SUBMIT';
const LOADER = 'LOGIN_LOADER';
const ME = 'LOGIN_ME';

export const constants = {
  SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const setMe = me => ({
  type: ME,
  me,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch, getStore) => {
  // Gets the user's token to be storaged in the local_storage
  const { values } = getStore().form.loginUser;
  data.username = values && values.username;
  dispatch(setLoader(true));
  api.post('user/token', data)
    .then((response) => {
      if (response.user.is_staff || response.user.is_superuser) {
        localStorage.setItem('token', response.token);
        dispatch(initializeForm('profile', response.user));
        dispatch(setMe(response.user));
        dispatch(push("/"));
      } else {
        NotificationManager.error('Bad credentials, try again.', 'ERROR', 0);
      }
    }).catch((error) => {
      console.log(error);
      NotificationManager.error('Bad credentials, try again.', 'ERROR', 0);
    }).finally(() => {
      dispatch(setLoader(false));
    });
};

export const getMe = () => (dispatch) => {
  // Gets the current user logged in the app
  api.get('/user/me')
    .then((me) => {
      dispatch(initializeForm('profile', me));
      dispatch(setMe(me));
    })
    .catch(() => {
    })
    .finally(() => {
    });
};

export const logOut = () => (dispatch) => {
  // Close the current session
  api.post('/user/logout')
    .then(() => {
    })
    .catch(() => {
    })
    .finally(() => {
    });
  localStorage.removeItem('token');
};


export const actions = {
  onSubmit,
  logOut,
};

export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [ME]: (state, { me }) => {
    return {
      ...state,
      me,
    };
  },
};

export const initialState = {
  loader: false,
  me: {},
};

export default handleActions(reducers, initialState);
