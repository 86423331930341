import React, { Component } from 'react';
import "./verify_login.css";


class VerifyLogin extends Component {
  render() {
    return (
      <div id="verify_login">
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            margin: "0",
            padding: "0",
            width: "100vw",
            height: "100vh",
            display: "flex",
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
          }}
        >
          <div
            style={{
              position: "absolute",
              marginLeft: "calc(50vw - 65px)",
              marginTop: "calc(50vh - 65px)",
              fontWeight: "bold",
              fontSize: "5rem",
              zIndex: "100",
              color: "black",
            }}
            className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
      </div>
    );
  }
}

export default VerifyLogin;
