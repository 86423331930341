import { connect } from 'react-redux';
import { onSubmit } from '../../../../redux/modules/cuenta/login';
import Login from './Login';


const ms2p = (state) => {
  return {
    loader: state.login.loader,
  };
};

const md2p = { onSubmit };

export default connect(ms2p, md2p)(Login);
