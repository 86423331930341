import { connect } from 'react-redux';
import { onSubmit } from '../../../../redux/modules/cuenta/register';
import Register from './Register';


const ms2p = (state) => {
  return {
    loader: state.registro.loader,
  };
};

const md2p = { onSubmit };

export default connect(ms2p, md2p)(Register);
