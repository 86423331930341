import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer d-flex p-2 px-3 bg-white border-top">
        <div className="container d-flex justify-content-end align-items-center">
          <span className="copyright">WMATA</span>
        </div>
      </footer>
    );
  }
}

export default Footer;
