import _ from "lodash";
import { api } from "api";
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { handleActions } from 'redux-actions';
import { initialize as initializeForm } from 'redux-form';

const url = 'user';

const LOADER_ADMINS = 'LOADER_ADMINS';
const SET_DATA_ADMINS = 'SET_DATA_ADMINS';
const SET_PAGE_ADMINS = 'SET_PAGE_ADMINS';
const SET_UPDATE_DATA_ADMINS ='SET_UPDATE_DATA_ADMINS';
const SET_BUSCADOR_ADMINS = 'SET_BUSCADOR_ADMINS';
const SET_FILTRO_ADMINS = 'SET_FILTRO_ADMINS';

export const listar = (page = 1) => (dispatch, getStore) => {
  // List all the admins
  dispatch({ type: LOADER_ADMINS, loader: true });
  const store = getStore();
  const search = store.admins.buscador;
  const filtro = store.admins.adminsAccountsFilter;
  const params = { page, search };

  if (filtro) {
    params[''] = filtro;
  }
  api.get(`${url}`, params).catch((error) => {
    dispatch({ type: LOADER_ADMINS, loader: false });
    Swal(
      'Error',
      error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
      'error'
    );
  }).then((data) => {
    if (data) {
      dispatch({ type: SET_DATA_ADMINS, data });
      dispatch({ type: SET_PAGE_ADMINS, page });
    }
    dispatch({ type: LOADER_ADMINS, loader: false });
  });
};

export const detail = id => (dispatch, getState) => {
  // Gets the full info of an user
  dispatch({ type: LOADER_ADMINS, loader: true });
  api.get(`${url}/${id}`).catch((error) => {
    dispatch({ type: LOADER_ADMINS, loader: false });
    if (error.statusCode === 404) {
      dispatch(push('/admin-accounts'));
    }
  }).then((data) => {
    dispatch({ type: LOADER_ADMINS, loader: false });
    if (data) {
      dispatch({ type: SET_UPDATE_DATA_ADMINS, updateData: data });
      dispatch(initializeForm('admins', data));
    }
  });
};

export const create = () => (dispatch, getStore) => {
  // Creates an user
  const formData = getStore().form.admins.values;
  dispatch({ type: LOADER_ADMINS, loader: true });
  api.post(`${url}`, formData).then((data) => {
    dispatch({ type: LOADER_ADMINS, loader: false });
    Swal('Éxito', 'The account has been created.', 'success')
      .then(() => {
        dispatch(push('/admin-accounts'));
      });
  }).catch((error) => {
    dispatch({ type: LOADER_ADMINS, loader: false });
    Swal(
      'Error',
      error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
      'error'
    );
  }).finally(() => {
    dispatch({ type: LOADER_ADMINS, loader: false });
  });
};

export const update = () => (dispatch, getStore) => {
  // Updates an user
  const formData = _.cloneDeep(getStore().form.admins.values);
  dispatch({ type: LOADER_ADMINS, loader: true });
  api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
      dispatch({ type: LOADER_ADMINS, loader: false });
      if (data) {
        Swal(
          'Éxito',
          'Datos editados correctamente.',
          'success'
        ).then(() => {
          dispatch(push('/admin-accounts'));
        });
      }
    })
    .catch((error) => {
      dispatch({ type: LOADER_ADMINS, loader: false });
      Swal(
        'Error',
        error.detail || 'Ha ocurrido un error, verifique los datos.',
        'error'
      );
      if (error.statusCode === 404) {
        dispatch(push('/admin-accounts'));
      }
    });
};

export const destroy = id => (dispatch, getStore) => {
  // Deletes an user
  dispatch({ type: LOADER_ADMINS, loader: true });
  api.eliminar(`${url}/${id}`).catch((error) => {
    dispatch({ type: LOADER_ADMINS, loader: false });
    Swal.fire('Error', 'The admin has not been deleted.', 'error')
      .then(() => {
        dispatch(listar(1));
      });
  }).then((data) => {
    dispatch({ type: LOADER_ADMINS, loader: false });
    Swal.fire('Success', 'The admin has been deleted.', 'success')
      .then(() => {
        dispatch(listar(1));
      });
  });
};

export const search = search => (dispatch) => {
  // Sets the search param for the request
  dispatch({ type: SET_BUSCADOR_ADMINS, buscador: search });
  dispatch(listar(1));
};

export const filtro = filtro => (dispatch) => {
  // Sets the value to filter
  dispatch({ type: SET_FILTRO_ADMINS, adminsAccountsFilter: filtro === 0 ? null : filtro });
  dispatch(listar(1));
};

export const actions = {
  listar,
  detail,
  create,
  update,
  destroy,
  search,
  filtro,
};

export const reducer = {
  [LOADER_ADMINS]: (state, { loader }) => {
    return { ...state, loader };
  },
  [SET_DATA_ADMINS]: (state, { data }) => {
    return { ...state, data };
  },
  [SET_PAGE_ADMINS]: (state, { page }) => {
    return { ...state, page };
  },
  [SET_UPDATE_DATA_ADMINS]: (state, { updateData }) => {
    return { ...state, updateData };
  },
  [SET_BUSCADOR_ADMINS]: (state, { buscador }) => {
    return { ...state, buscador };
  },
  [SET_FILTRO_ADMINS]: (state, { adminsAccountsFilter }) => {
    return { ...state, adminsAccountsFilter };
  },
};

export const initialState = {
  loader: false,
  page: 1,
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  buscador: '',
  adminsAccountsFilter: null,
  updateData: {},
};

export default handleActions(reducer, initialState);
