import moment from 'moment';

export const PAST = 0;
export const TODAY = 1;
export const UPCOMING = 2;

export const PENDING = 0;
export const APPROVED = 1;
export const DENIED = 2;

export const customStylesModal = {
	content: {
		top                   : '50%',
		left                  : '50%',
		right                 : 'auto',
		bottom                : 'auto',
		marginRight           : '-50%',
		transform             : 'translate(-50%, -50%)',
		width                 : '100vh',
		maxWidth              : '100%',
        minHeight             : '50vh'
		// height                : '90vh',
	}
};

export const calcDiffTripDate = (date) => {
    let now = moment();
    date = moment(date, 'YYYY-MM-DDTHH:mm:ss');
    let difYear = date.diff(now, 'years');
    let difMonth = date.diff(now, 'months');
    let difDay = date.diff(now, 'days');
    let difHour = date.diff(now, 'hours');
    let difMinute = date.diff(now, 'minutes');

    if(difYear > 0)
        return difYear + ' Y';
    else if(difMonth > 0)
        return difMonth + ' M';
    else if(difDay > 0)
        return difDay + ' D';
    else if(difHour > 0)
        return difHour + ' H';
    else if(difMinute > 0)
        return difMinute + ' m';
    else
        return 'T'
}
