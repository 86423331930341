import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import NewTripForm from '../newTripForm';
import moment from 'moment';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';


const ConfirmDetails = (props) => {

    const { prices, values, goBack, onSubmit } = props;

    return (
        <div className="mt-4">
            {prices && values && (
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column">
                        <h4>Trip Details</h4>
                        <div className="">
                            <strong className="font-weight-bold"> Date: </strong> <label> {moment(values.trip_pick_up_date).format('MM/DD/YYYY')}</label>
                        </div>
                        <div className="">
                            <strong className="font-weight-bold"> Time: </strong>
                            <label>
                                {values.time
                                    ? ( values.time.hour > 12
                                        ? ( values.time.hour - 12 +':'+ values.time.minutes + ' PM' )
                                        : ( values.time.hour + ':' + values.time.minutes + ' AM' ) )
                                    : " "
                                }
                            </label>
                        </div>
                        <div className="">
                            <strong className="font-weight-bold"> From: </strong> <label> {values.trip_pick_up_location}</label>
                        </div>
                        <div className="">
                            <strong className="font-weight-bold"> To: </strong> <label> {values.trip_drop_off_location}</label>
                        </div>
                        <div className="">
                            <strong className="font-weight-bold"> Regular Price: </strong> <label> $ {prices.regular_price}</label>
                        </div>
                        <div className="">
                            <strong className="font-weight-bold"> Wmata Subsidy: </strong> <label> $ {prices.wmata_subsidy}</label>
                        </div>
                        <div className="">
                            <strong className="font-weight-bold"> Final Payment: </strong> <label> $ {prices.final_payment}</label>
                        </div>
                    </div>
                    {values.return_trip && (
                        <React.Fragment>
                            <br/>
                            <h4> Return Trip details </h4>
                            <div className="d-flex flex-column">
                                <div className="">
                                    <strong className="font-weight-bold"> Date: </strong> <label> {moment(values.return_trip_date).format('MM/DD/YYYY')}</label>
                                </div>
                                <div className="">
                                    <strong className="font-weight-bold"> Time: </strong>
                                    <label>
                                        {values.return_trip_time
                                            ? ( values.return_trip_time.hour > 12
                                                ? ( values.return_trip_time.hour - 12 +':'+ values.return_trip_time.minutes + ' PM' )
                                                : ( values.return_trip_time.hour + ':' + values.return_trip_time.minutes + ' AM' ) )
                                            : " "
                                        }
                                    </label>
                                </div>
                                <div className="">
                                    <strong className="font-weight-bold"> From: </strong> <label> {values.trip_drop_off_location}</label>
                                </div>
                                <div className="">
                                    <strong className="font-weight-bold"> To: </strong> <label> {values.trip_pick_up_location}</label>
                                </div>
                                <div className="">
                                    <strong className="font-weight-bold"> Regular Price: </strong> <label> $ {prices.return_regular_price}</label>
                                </div>
                                <div className="">
                                    <strong className="font-weight-bold"> Wmata Subsidy: </strong> <label> $ {prices.return_wmata_subsidy}</label>
                                </div>
                                <div className="">
                                    <strong className="font-weight-bold"> Final Payment: </strong> <label> $ {prices.return_final_payment}</label>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )}
            <div className="d-flex flex-column flex-md-row justify-content-center mt-3">
                <button className="btn btn-secondary mr-md-3" onClick={()=>goBack()}>
                    Back
                </button>
                <button className="btn btn-primary mt-2 mt-md-0" onClick={()=>onSubmit(values)}>
                    Save
                </button>
            </div>
        </div>
    )
}


class ModalNewTrip extends React.Component {

    state = {
        step: 0,
        values: undefined,
    }

    getTripPrice = async (values) => {
        this.props.getPrice(values).then(result=>{
            if(result)
                this.setState({ values, step: this.state.step + 1 });
        })
    }

    resetModal = () => {
        this.setState({ step: 0, values: undefined });
    }

    goBack = () => {
        this.props.initialize('newTripForm', this.state.values);
        this.setState({ step: this.state.step - 1 });
    }

    closeModal = () => {
        this.resetModal();
        this.props.closeModal();
    }

    onSubmit = async (values) => {
        await this.props.onSubmit(values, this.resetModal);
    }

    render(){

        const { modalOpen, loader_create = false, loading_form = false } = this.props;

        return (
            <Modal isOpen={modalOpen} toggle={this.closeModal} zIndex={115000} className="modal-lg">
                <ModalBody>

                    <div className="d-flex flex-column">
                        <div className="d-flex">
                            <div className="flex-1 text-center">
                                <h3> New Trip </h3>
                            </div>
                            <i className="clickable" onClick={this.closeModal}>
                                <span className="material-icons text-dark">close</span>
                            </i>
                        </div>
                        <hr className="w-100"/>
                        {this.state.step === 0 && (
                            <NewTripForm
                                onSubmit={this.getTripPrice}
                                cancel={this.closeModal}
                                txtBtnClose="Cancel"
                                txtBtnSubmit="Next"
                                trip_pick_up_location={this.state.values ? this.state.values.trip_pick_up_location : undefined}
                                trip_drop_off_location={this.state.values ? this.state.values.trip_drop_off_location : undefined}
                                time={this.state.values ? moment(`${this.state.values.time.hour}:${this.state.values.time.minutes}`, 'HH:mm') : undefined}
                                return_trip_time={this.state.values && this.state.values.return_trip_time
                                    ? moment(`${this.state.values.return_trip_time.hour}:${this.state.values.return_trip_time.minutes}`, 'HH:mm')
                                    : undefined
                                }
                            />
                        )}

                        {this.state.step === 1 && (
                            <LoadMask loading={loading_form || loader_create} blur>
                                <ConfirmDetails
                                    {...this.props}
                                    {...this.state}
                                    onSubmit={this.onSubmit}
                                    goBack={this.goBack}
                                />
                            </LoadMask>
                        )}

                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

const mstp = (state) => ({
    loading_form: state.tripFormData.loading
});

export default connect(mstp, {initialize})(ModalNewTrip);
