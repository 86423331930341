import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { api } from "api";
import { logOut, getMe } from "./redux/modules/cuenta/login";
import LoadMask from "./common/components/Utils/LoadMask/LoadMask";


class SuccessLoginOauth extends React.Component{

    componentDidMount(){
        const { history, getMe } = this.props;

        const { token, } = this.props.match.params;
        if(token){
            window.localStorage.setItem('token', token);
            try {
                history.replace('/')
            } catch(error) {
                console.log(error);
                history.replace('/login');
            }
        } else {
            history.replace('/login');
        }
    }

    render(){

        const {
            component: Component,
            ...rest
        } = this.props;

        return (
            <Route {...rest} render={(props) => <LoadMask loading />} />
        );
    }
};

const mdtp = { getMe };

export default connect(null, mdtp)(SuccessLoginOauth);
