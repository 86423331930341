import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderDatePicker2, renderField, renderNumber, renderPlacesAutocomplete, renderSwitch, renderTextArea } from '../Utils/renderField/renderField';
import renderTimePicker from '../Utils/renderField/TimePicker/index';
import { validate, validators, combine } from "validate-redux-form";
import { validAddress } from '../../../utility/validation';
import { connect } from 'react-redux';
import LoadMask from '../Utils/LoadMask/LoadMask';
import { actions } from '../../../redux/modules/tripFormData/index';


class Form extends React.Component {

    render(){

        const { cancel, handleSubmit, setDetails, formValues, loading, txtBtnSubmit = 'Save', txtBtnClose = "Cancel" } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <LoadMask loading={loading} blur>
                    <div className="d-flex flex-column">
                        <div className="row">
                            <div className="col-12 col-md-6 form-group">
                                <label> Pick Up* </label>
                                <Field
                                    name="trip_pick_up_location"
                                    component={renderPlacesAutocomplete}
                                    className="form-control"
                                    setDetails={(data)=> setDetails(data, 'from')}
                                    Value={this.props.trip_pick_up_location}
                                    placeholder="Pick up location"
                                />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <label> Drop off* </label>
                                <Field
                                    name="trip_drop_off_location"
                                    component={renderPlacesAutocomplete}
                                    setDetails={(data)=> setDetails(data, 'to')}
                                    Value={this.props.trip_drop_off_location}
                                    className="form-control"
                                    placeholder="Drop off location"
                                />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <label> Date* </label>
                                <Field
                                    id="trip_pick_up_date"
                                    component={renderDatePicker2}
                                    numberOfMonths={1}
                                    name="trip_pick_up_date"
                                    className="w-100"
                                />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <label> Time* </label>
                                <Field
                                    id="timepicker1"
                                    name="time"
                                    placeholder="12:00 PM"
                                    component={renderTimePicker}
                                    Value={this.props.time}
                                    style = {{height: 'none'}}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <label> First name* </label>
                                <Field
                                    component={renderField}
                                    name="first_name"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <label> Last Name* </label>
                                <Field
                                    component={renderField}
                                    name="last_name"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <label> WMATA ID* </label>
                                <Field
                                    component={renderNumber}
                                    thousandSeparator=""
                                    placeholder="12456"
                                    name="username"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <label> Phone number </label>
                                <Field
                                    component={renderNumber}
                                    numberFormat={"(###) ###-####"}
                                    placeholder="(123) 456-7890"
                                    name="phone_number"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <label> Special notes </label>
                                <Field
                                    component={renderTextArea}
                                    cols={3}
                                    name="special_notes"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <label> Add return trip </label>
                                <Field
                                    name="return_trip"
                                    component={renderSwitch}
                                />
                            </div>
                            {formValues && formValues.values && formValues.values.return_trip && (
                                <React.Fragment>
                                    <div className="col-12 col-md-6 form-group">
                                        <label>Return Date</label>
                                        <Field
                                            id="return_trip_date"
                                            name="return_trip_date"
                                            component={renderDatePicker2}
                                            numberOfMonths={1}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 form-group">
                                        <label> Return Time </label>
                                        <Field
                                            id="timepicker2"
                                            name="return_trip_time"
                                            Value={this.props.return_trip_time}
                                            placeholder="12:00 PM"
                                            component={renderTimePicker}
                                            style = {{height: 'none'}}
                                            className="form-control"
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="d-flex justify-content-center pt-4">
                            <button type="button" className="btn btn-secondary px-2 mx-2" onClick={()=>cancel()}>
                                {txtBtnClose}
                            </button>
                            <button type="submit" className="btn btn-primary px-2 mx-2">
                                {txtBtnSubmit}
                            </button>
                        </div>
                    </div>
                </LoadMask>
            </form>
        )
    }
}

const NewTripForm = reduxForm({
    form: 'newTripForm',
    validate: (data, props) =>
        validate(data, {
            trip_pick_up_date: validators.exists()('This field is required'),
            trip_pick_up_location: combine(
                validators.exists()('This field is required'),
                validAddress(props.from)('Please select an exact address')
            ),
            trip_drop_off_location: combine(
                validators.exists()('This field is required'),
                validAddress(props.to)('Please select an exact address')
            ),
            time: validators.exists()('This field is required'),
            username: validators.exists()('This field is required'),
            first_name: validators.exists()('This field is required'),
            last_name: validators.exists()('This field is required'),
            phone_number: validators.exists()('This field is required'),
            return_trip_date: validators.exists()('This field is required'),
            return_trip_time: validators.exists()('This field is required'),
        })
})(Form);

const mstp = (state) => ({
    ...state.tripFormData,
    formValues: state.form.newTripForm,
})

const mdtp = actions;

export default connect(mstp, mdtp)(NewTripForm);
