import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios/usuarios';
import notificaciones from './modules/notificaciones/notificaciones';
import admins from './modules/adminAccounts/adminAccounts';
import wmata_accounts from './modules/WMATA/accounts/accounts';
import tripReports from './modules/reports/trips';
import trips from './modules/trips/index';
import tripFormData from './modules/tripFormData/index';

export default combineReducers({
  form: formReducer,
  login,
  register,
  profile,
  usuarios,
  routing,
  tripReports,
  trips,
  tripFormData,
  wmata_accounts,
  admins,
  notificaciones,
});
