import React, { Component } from "react";
import swal from "sweetalert2";

import "./UnstyledFileUploader.css";

class UnstyledFileUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            submit: false,
            document: null,
            fileName: null,
        };

        this.onFileChange = this.onFileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onFileChange(e, file) {
        const { onFileChange } = this.props;
        onFileChange(e, file);
        file = file || e.target.files[0];
        const reader = new FileReader();

        if (file) {
            this.setState({ loaded: false });

            reader.onload = (e) => {
                this.setState({
                    loaded: true,
                    submit: true,
                });

                this.setState({
                    document: e.target.result,
                    fileName: file.name,
                });
            };

            reader.readAsBinaryString(file);
        }
    }

    handleSubmit() {
        const { submit } = this.props;
        const { document } = this.state;

        swal.fire({
            title: "You want to upload the file?",
            text: "You won't be able to revert this!",
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#DE020F",
            cancelButtonColor: "#545b62",
            confirmButtonText: "Yes, upload",
        }).then((result) => {
            if (result.value) {
                submit(document).then(() => {
                    swal.fire(
                        "File uploaded",
                        "The file has been uploaded",
                        "success"
                    );
                });
            }
        });
    }

    render() {
        const { loaded, submit, fileName } = this.state;
        const { defaultButton, accept, label, invalid, error, classNameLabel, classNameSubmit, classNameContainer } = this.props;

        return (
            <div className={`mr-3 ${classNameContainer}`}>
                <input type="file" id="file" onChange={this.onFileChange} accept={accept} />
                <label
                    htmlFor="file"
                    className={"btn btn-primary " + (submit ? "loaded" : "") + " " + classNameLabel}
                >
                    {fileName || label || "Import"}
                    {!defaultButton &&  (<React.Fragment>&nbsp;<i className="fa fa-upload" aria-hidden="true" /></React.Fragment>)}
                </label>
                {invalid && !defaultButton && <div className="invalid-feedback"> {error} </div>}
                {loaded && defaultButton && (
                    <button
                        type="button"
                        onClick={this.handleSubmit}
                        className="btn btn-primary loaded--left"
                    >
                        <i className="fa fa-upload" aria-hidden="true" />
                    </button>
                )}
                {!defaultButton && (
                    <button type="submit"
                        className={classNameSubmit}
                    >
                        Upload
                    </button>
                )}
            </div>
        );
    }
}

export default UnstyledFileUploader;
