import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from 'Utils/LoadMask/LoadMask';

export default class Grids extends Component {
  static propTypes = {
    deny: PropTypes.func.isRequired,
    detail: PropTypes.func.isRequired,
    loader: PropTypes.bool.isRequired,
    approve: PropTypes.func.isRequired,
    updateData: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const { detail, match } = this.props;
    detail(match.params.id);
  }

  render() {
    const { updateData, loader, approve, deny, hardDelete } = this.props;

    return (
      <div className="py-4">
        <h3>WMATA PENDING ACCOUNT</h3>
        <div className="row">
          <div className="mb-4 col-12">
            <div className="mb-4 card card-small">
              <LoadMask loading={loader} blur_1>
                <div className="border-bottom card-header">
                  <h6 className="m-0">WMATA ID { updateData.username } ACCOUNT INFORMATION </h6>
                </div>
                <div className="px-3 py-3">
                  <div className="px-0 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-3">
                      <div className="d-flex flex-column">
                        <label className="label-title">First Name</label>
                        <label className="label-field">{updateData.first_name}</label>
                      </div>
                      <div className="d-flex flex-column">
                        <label className="label-title">Last Name</label>
                        <label className="label-field">{updateData.last_name}</label>
                      </div>
                      <div className="d-flex flex-column">
                        <label className="label-title">Email</label>
                        <label className="label-field">{updateData.email}</label>
                      </div>
                      <div className="d-flex flex-column">
                        <label className="label-title">Phone Number</label>
                        <label className="label-field">{ updateData.profile && updateData.profile.cell_phone}</label>
                      </div>
                      {
                        updateData && updateData.profile && updateData.profile.account_status_message && (
                          <div className="d-flex flex-column">
                            <label className="label-title-warning">Reason for the last denial</label>
                            <label className="label-field">{updateData.profile.account_status_message}</label>
                          </div>
                        )
                      }
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          onClick={() => approve()}
                          className="btn btn-success my-3 mx-2 wmata-buttons-text"
                        >
                          Approve account
                        </button>
                        <button
                          type="button"
                          onClick={() => deny()}
                          className="btn btn-primary my-3 mx-2 wmata-buttons-text"
                        >
                          Deny account
                        </button>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div onClick={() => hardDelete(updateData.id)} style={{ textDecoration: "underline", cursor: "pointer" }}>Delete account</div>
                      </div>
                    </div>
                  </div>
                </div>
              </LoadMask>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
