import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";


export default class Grids extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    loader: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    list_denied_accounts: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const { list_denied_accounts } = this.props;
    list_denied_accounts();
  }

  render() {
    const { data, loader, list_denied_accounts: onPageChange, onSortChange, page } = this.props;

    return (
      <div className="py-4">
        <h3>WMATA DENIED ACCOUNTS</h3>
        <div className="row">
          <div className="mb-4 col-12">
            <div className="mb-4 card card-small">
              <div className="border-bottom card-header"><h6 className="m-0">Denied accounts</h6></div>
              <div className="p-0 px-3 pt-3">
                <Grid
                  hover
                  striped
                  page={page}
                  data={data}
                  loading={loader}
                  onPageChange={onPageChange}
                  onSortChange={onSortChange}
                >
                  <TableHeaderColumn
                    dataField="username"
                    dataSort
                  >
                    WMATA ID Number
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="first_name"
                    dataSort
                  >
                    First Name
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="last_name"
                    dataSort
                  >
                    Last Name
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="id"
                    isKey
                    dataAlign="center"
                    dataSort
                    dataFormat={standardActions({ detail: "/denied-accounts" })}
                  >
                    Actions
                  </TableHeaderColumn>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
