import { connect } from 'react-redux';
import {
  retrieve,
  listPastTrips,
  listTodayTrips,
  listUpcomingTrips,
  setSearchPast,
  setSearchToday,
  setSearchUpcoming,
  tripUpdateDate,
} from '../../../redux/modules/reports/trips';
import TripReports from './TripReports';


const ms2p = (state) => {
  return {
    tripLoader: state.tripReports.tripLoader,
    retrievedTrip: state.tripReports.retrievedTrip,
    todayTripsLoader: state.tripReports.todayTripsLoader,
    todayTripsPage: state.tripReports.todayTripsPage,
    todayTripsData: state.tripReports.todayTripsData,
    pastTripsLoader: state.tripReports.pastTripsLoader,
    pastTripsPage: state.tripReports.pastTripsPage,
    pastTripsData: state.tripReports.pastTripsData,
    upcomingTripsLoader: state.tripReports.upcomingTripsLoader,
    upcomingTripsPage: state.tripReports.upcomingTripsPage,
    upcomingTripsData: state.tripReports.upcomingTripsData,
  };
};

const md2p = {
  retrieve,
  listPastTrips,
  setSearchPast,
  listTodayTrips,
  setSearchToday,
  tripUpdateDate,
  listUpcomingTrips,
  setSearchUpcoming,
};

export default connect(ms2p, md2p)(TripReports);
