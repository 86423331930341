import { api } from "api";
import Swal from 'sweetalert2';
import { handleActions } from 'redux-actions';
import moment from "moment";
import { PAST, UPCOMING, TODAY } from "../../../utility/constants";

const LOADING_FORM_TRIP = "LOADING_FORM_TRIP";
const FROM = "FROM_TRIPS";
const TO = "TO_TRIPS";


const setDetails = (data, type) => (dispatch, getStore) => {
	dispatch({ type: type.toUpperCase()+'_TRIPS', data })
}

export const setLoading = (value) => ({
	type: LOADING_FORM_TRIP, loading: value
})

export const actions = {
	setDetails,
};

export const reducer = {
	[FROM]: (state, { data }) => ({ ...state, from: data }),
	[TO]: (state, { data }) => ({ ...state, to: data }),
	[LOADING_FORM_TRIP]: (state, { loading }) => ({ ...state, loading }),
};

export const initialState = {
	loading: false,
	from:{
		city: undefined,
		state: undefined,
		lat: undefined,
		lng: undefined,
		postal_code: undefined,
	},
	to: {
		city: undefined,
		state: undefined,
		lat: undefined,
		lng: undefined,
		postal_code: undefined,
	},
};

export default handleActions(reducer, initialState);
