import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from 'Utils/LoadMask/LoadMask';
import { Link } from "react-router-dom";

export default class Grids extends Component {
  static propTypes = {
    loader: PropTypes.bool.isRequired,
    detail: PropTypes.func.isRequired,
    updateData: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const { detail, match } = this.props;
    detail(match.params.id);
  }

  render() {
    const { updateData, loader } = this.props;
    return (
      <div className="py-4">
        <h3>ADMIN ACCOUNT</h3>
        <div className="row">
          <div className="mb-4 col-12">
            <div className="mb-4 card card-small">
              <div className="border-bottom card-header"><h6 className="m-0"> ACCOUNT INFORMATION </h6></div>
              <LoadMask loading={loader} blur_1>
                <div className="px-3 py-3">
                  <div className="px-0 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-3">
                      <div className="d-flex flex-column">
                        <label className="label-title">Username</label>
                        <label className="label-field">{updateData.username}</label>
                      </div>
                      <div className="d-flex flex-column">
                        <label className="label-title">Superuser</label>
                        <label className="label-field">{updateData.is_superuser ? "YES" : "NO"}</label>
                      </div>
                      <div className="d-flex flex-column">
                        <label className="label-title">First Name</label>
                        <label className="label-field">{updateData.first_name}</label>
                      </div>
                      <div className="d-flex flex-column">
                        <label className="label-title">Last Name</label>
                        <label className="label-field">{updateData.last_name}</label>
                      </div>
                      <div className="d-flex flex-column">
                        <label className="label-title">Email</label>
                        <label className="label-field">{updateData.email}</label>
                      </div>
                      <div className="d-flex flex-column">
                        <label className="label-title">Phone Number</label>
                        <label className="label-field">{ updateData.profile && updateData.profile.cell_phone}</label>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Link to="/admin-accounts" className="btn btn-info my-3 mx-2 wmata-buttons-text">
                          Go Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </LoadMask>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
