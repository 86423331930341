import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import moment from 'moment';
import { Field, initialize, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderDatePicker2, renderField, renderNumber } from '../../Utils/renderField/renderField';
import { combine, validate, validatorFromFunction } from 'validate-redux-form';
import validators from 'validate-redux-form/compiled/validators';


const Form = (props) => {

    const { handleSubmit, cancel } = props;

    return (
        <form className="" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-12 col-md-6 form-group">
                    <label>PO Number</label>
                    <Field
                        name="poNumber"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label>Date</label>
                    <Field
                        id="date"
                        name="date"
                        numberOfMonths={1}
                        component={renderDatePicker2}
                        className="form-control"
                    />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label>Invoice Period From</label>
                    <Field
                        id="invoice_period_from"
                        name="invoice_period_from"
                        numberOfMonths={1}
                        component={renderDatePicker2}
                        isOutsideRange={false}
                        className="form-control"
                    />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label>Invoice Period To</label>
                    <Field
                        id="invoice_period_to"
                        name="invoice_period_to"
                        numberOfMonths={1}
                        component={renderDatePicker2}
                        isOutsideRange={false}
                        className="form-control"
                    />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label>Invoice Number</label>
                    <Field
                        name="invoice_number"
                        component={renderNumber}
                        thousandSeparator=""
                        className="form-control"
                    />
                </div>

            </div>
            <div className="d-flex justify-content-center pt-4">
                <button type="button" className="btn btn-secondary px-2 mx-2" onClick={()=>cancel()}>
                    Cancel
                </button>
                <button type="submit" className="btn btn-primary px-2 mx-2">
                    Download
                </button>
            </div>
        </form>
    )
}


export const validateDateRange = validatorFromFunction((value, data) => {
    let invoice_from = data && data.invoice_period_from && moment(data.invoice_period_from).startOf('day');
    let invoice_to = data && data.invoice_period_to &&  moment(data.invoice_period_to).endOf('day');
    if(invoice_from && invoice_to){
        return invoice_from.isSameOrBefore(invoice_to);
    } else {
        return value ? true : false;
    }
})

const FieldsForm = reduxForm({
    form: 'fieldForm',
    initialValues: {
        date: new Date(),
        invoice_period_from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        invoice_period_to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    },
    validate: (data, props) =>
        validate(data,{
            poNumber: validators.exists()('This field is required'),
            date: validators.exists()('This field is required'),
            invoice_number: validators.exists()('This field is required'),
            invoice_period_from: combine(
                validators.exists()('This field is required'),
                validateDateRange(data)('Invalid date')
            ),
            invoice_period_to: combine(
                validators.exists()('This field is required'),
                validateDateRange(data)('Invalid date')
            )
        })
})(Form);

class ModalDownload extends React.Component {

    state = {
        step: 0,
        values: undefined,
    }

    resetModal = () => {
        this.setState({ step: 0, values: undefined });
    }

    closeModal = () => {
        this.resetModal();
        this.props.closeModal();
    }

    onSubmit = async (values) => {
        await this.props.onSubmit(values);
        this.resetModal();
    }

    render(){

        const { modalOpen, loader_create = false } = this.props;

        return (
            <Modal isOpen={modalOpen} toggle={this.closeModal} zIndex={115000} className="modal-lg">
                <ModalBody>

                    <div className="d-flex flex-column">
                        <div className="d-flex">
                            <div className="flex-1 text-center">
                                <h3> Download report </h3>
                            </div>
                            <i className="clickable" onClick={this.closeModal}>
                                <span className="material-icons text-dark">close</span>
                            </i>
                        </div>
                        <hr className="w-100"/>

                        <LoadMask loading={loader_create} blur>
                            <FieldsForm
                                onSubmit={this.onSubmit}
                                cancel={this.closeModal}
                            />
                        </LoadMask>


                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

const mstp = (state) => ({
    loader_create: state.trips.loader_create
});

export default connect(mstp, {initialize})(ModalDownload);
