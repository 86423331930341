import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';
import PropTypes from 'prop-types';

export default class Grids extends Component {
  static propTypes = {
    deny: PropTypes.func.isRequired,
    detail: PropTypes.func.isRequired,
    loader: PropTypes.bool.isRequired,
    updateData: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const { detail, match } = this.props;
    detail(match.params.id);
  }

  render() {
    const { updateData, loader, deny } = this.props;

    return (
      <div className="py-4">
        <h3>WMATA APPROVED ACCOUNT</h3>
        <div className="row">
          <div className="mb-4 col-12">
            <div className="mb-4 card card-small">
              <LoadMask loading={loader} blur_1>
                <div className="border-bottom card-header">
                  <h6 className="m-0">WMATA ID { updateData.username } ACCOUNT INFORMATION </h6>
                </div>
                <div className="px-3 py-3">
                  <div className="px-0 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-3">
                      <div className="d-flex flex-column">
                        <label className="label-title">First Name</label>
                        <label className="label-field">{updateData.first_name}</label>
                      </div>
                      <div className="d-flex flex-column">
                        <label className="label-title">Last Name</label>
                        <label className="label-field">{updateData.last_name}</label>
                      </div>
                      <div className="d-flex flex-column">
                        <label className="label-title">Email</label>
                        <label className="label-field">{updateData.email}</label>
                      </div>
                      <div className="d-flex flex-column">
                        <label className="label-title">Phone Number</label>
                        <label className="label-field">{ updateData.profile && updateData.profile.cell_phone}</label>
                      </div>
                      <div className="d-flex flex-column">
                        <label className="label-title">WMATA ID Number</label>
                        <label className="label-field">{updateData.username}</label>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          onClick={() => deny()}
                          className="btn btn-primary my-3 mx-2 wmata-buttons-text"
                        >
                          Deny account
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </LoadMask>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
