import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import Grid from "../../Utils/Grid";
import { PAST, UPCOMING, TODAY, calcDiffTripDate } from "../../../../utility/constants";
import { Field, reduxForm } from 'redux-form';
import { renderDropdownSelection, renderField, renderSwitch } from '../../Utils/renderField/renderField';
import ModalDetail from './modalDetail';
import ModalNewTrip from './modalNewTrip';
import ModalDownload from './modalDownload';
import ModalImport from './modalImport';

const dateFormat = (dataString) => {
	try {
		const date = moment(dataString, "YYYY-MM-DDTHH:mm:ss");
		return date.format("MM/DD/YYYY HH:mm");
	} catch (error) {
		return null;
	}
}

const FiltersForm = reduxForm({
	form: "filtersTrip",
})((props) => {

	const { setSearch, listTrips } = props;

	return (
		<div className="d-flex py-2 flex-column flex-md-row">
			<div className="flex-1 d-flex flex-column">
				<div className="d-flex flex-column flex-md-row">
					<div className="flex-1">
						<Field
							type="search"
							name="search"
							className="form-control mb-3"
							component={renderField}
							onChange={el => setSearch(el.currentTarget.value)}
							placeholder="Search by wmata id, Livery No, authorize transaction No"
						/>
					</div>
					<div className="d-flex align-items-center justify-content-center px-2">
						<Field
							name="filter"
							icon='filter'
							component={renderDropdownSelection}
							className="d-flex justify-content-end"
							placeholder="Filter by"
							clearable
							scrolling
							options={[
								{key: "Upcoming", text: "Upcoming", value: UPCOMING},
								{key: "Booking Date", text: "Booking Date", value: TODAY},
								{key: "Past", text: "Past", value: `${PAST}`},
							]}
							cb={(data)=>listTrips(1)}
						/>
					</div>
				</div>

				{/* TODO: Date range filter */}
			</div>
			<div className="flex-1 d-flex flex-column justify-content-end">
				<div className="d-flex justify-content-end align-items-end">
					<label htmlFor="canceled" className="mb-0">Show Canceled&nbsp;</label>
					<Field
						name="canceled"
						component={renderSwitch}
						height={22}
						width={44}
						cb={()=>listTrips(1)}
					/>
				</div>
			</div>
		</div>
	)
});

class TripList extends Component {
	static propTypes = {
		retrieve: PropTypes.func.isRequired,
		listTrips: PropTypes.func.isRequired,
		TripsLoader: PropTypes.bool.isRequired,
		TripsPage: PropTypes.number.isRequired,
		TripsData: PropTypes.object.isRequired,
		setSearch: PropTypes.func.isRequired,
		tripUpdateBillingNotes: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			selectedTrip: null,
			modalTrip: false,
			modalDownload: false,
			modalImport: false,
		};
		moment.locale("en");
	}

	componentDidMount() {
		this.props.listTrips();
	}

	openModal = (trip) => {
		const { retrieve, } = this.props;
		if (trip) {
			retrieve(trip.id);
			this.setState({ selectedTrip: trip, }, () => this.setState({ showModal: true }));
		}
	}

	closeModal = () => {
		this.setState({ showModal: false });
	}

	paidAmount(transactions) {
		let total = 0;
		for (let index = 0; index < transactions.length; index++) {
			const element = transactions[index];
			if (element.cancelled) {
				continue
			} else {
				total += Number(element.amount);
			}
		}
		return total.toFixed(2)
	}

	setModalTrip = (modalTrip) => this.setState({ modalTrip });
	setModalDownload = (modalDownload) => this.setState({ modalDownload });
	setModalImport = (modalImport) => this.setState({ modalImport });

	render() {

		const { tripLoader, retrievedTrip, TripsLoader, TripsPage, TripsData, getPrice,
			setSearch, listTrips, tripUpdateDate, createTrip, tripUpdateBillingNotes,
			prices, loader_create, downloadSummary, importXls
		} = this.props;

		const { showModal, modalTrip, modalDownload, modalImport } = this.state;

		return (
			<div className="py-4">
				<div className="row">
					<div className="mb-4 col-lg-12">
						<div className="mb-4 card card-small">
							<div className="border-bottom card-header d-flex">
								<div className="flex-1 d-flex justify-content-center">
									<h3 className="m-0">TRIPS</h3>
								</div>
								<div className="d-flex justify-content-end">
									<button className="btn btn-primary mr-md-2" onClick={()=>this.setModalImport(true)}>
										<i className="material-icons" style={{ fontSize: "0.75rem" }}> file_upload </i>
										Import
									</button>
									<button className="btn btn-primary mr-md-2" onClick={()=>this.setModalDownload(true)}>
										<i className="material-icons" style={{ fontSize: "0.75rem" }}> file_download </i>
										Summary
									</button>
									<button className="btn btn-primary" onClick={()=>this.setModalTrip(true)}>
										New trip
									</button>
								</div>
							</div>
							<div className="p-0 px-3 pt-3">
								<div className="py-3 px-3">
									<FiltersForm
										setSearch={setSearch}
										listTrips={listTrips}
									/>
									<Grid
										hover
										page={TripsPage}
										data={TripsData}
										loading={TripsLoader}
										onPageChange={listTrips}
										onSortChange={null}
										onRowClick={tripInfo => this.openModal(tripInfo)}
									>
										<TableHeaderColumn
											dataField="id"
											isKey
											className="d-none"
											dataFormat={(cell, row) =>
												<div className="d-flex flex-column py-2">
													<div className="d-flex flex-column flex-md-row">
														<div className="col-12 col-md-4 px-md-0 d-flex flex-column">
															<label> <strong className="font-weight-bold"> PU Date: </strong> {dateFormat(row.trip_pick_up_date)} </label>
															<label>
																<strong className="font-weight-bold"> Livery No: </strong>
																<span style={{ color: row.trip_cancelled && "#D50032", textDecoration: row.trip_cancelled && "line-through" }}>
																	{row.trip_order_number}
																</span>
															</label>
															<label> <strong className="font-weight-bold"> Wmata Id: </strong> {row.user} </label>
														</div>
														<div className="col-12 col-md-8 d-flex flex-column">
															<div className="d-flex flex-column flex-md-row">
																<div className="col-12 col-md-6 px-0 pr-md-1 pl-md-0 d-flex flex-column flex-lg-row">
																	<strong className="font-weight-bold"> PU Address:&nbsp;</strong>
																	<label className="text-wrap"> {row.trip_pick_up_location} </label>
																</div>
																<div className="col-12 col-md-6 px-0 pr-md-0 pl-md-1 d-flex flex-column flex-lg-row">
																	<strong className="font-weight-bold"> DO Address:&nbsp;</strong>
																	<label className="text-wrap"> {row.trip_drop_off_location}</label>
																</div>
															</div>
														</div>
													</div>

													<div className="d-flex flex-column flex-md-row">
														<div className="flex-1 d-none d-md-flex flex-column"></div>
														<div className="d-flex flex-column h-100">
															<div className="d-flex justify-content-end">
																<span className="t-center px-3"
																	style={{
																		background: "#C0C0C0",
																		borderRadius: "0.4rem",
																		fontSize: "calc(0.8em + 0.2vw)"
																	}}
																>
																	{calcDiffTripDate(row.trip_pick_up_date)}
																</span>
															</div>
															<div className="flex-1 d-md-none d-flex flex-column align-items-end">
																{/* FOR FLIGHT NUMBER */}
															</div>
															<div className="d-flex justify-content-end">
																<div className="mt-2 px-3"
																	style={{
																		background: "#C0C0C0",
																		borderRadius: "0.4rem",
																	}}
																>
																	<span>
																		<small style={{ fontSize: "calc(0.8em + 0.2vw)" }}> {row.id} </small>
																	</span>
																</div>
															</div>
														</div>
													</div>

												</div>
											}
										></TableHeaderColumn>
									</Grid>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ModalDetail
					tripLoader={tripLoader}
					retrievedTrip={retrievedTrip}
					showModal={showModal}
					closeModal={this.closeModal}
					tripUpdateDate={tripUpdateDate}
					paidAmount={this.paidAmount}
					tripUpdateBillingNotes={tripUpdateBillingNotes}
				/>

				<ModalNewTrip
					modalOpen={modalTrip}
					prices={prices}
					loader_create={loader_create}
					closeModal={()=>this.setModalTrip(false)}
					getPrice={getPrice}
					onSubmit={(values, successAction) => createTrip(values, () => {
						successAction();
						this.setModalTrip(false);
						listTrips();
					})}
				/>

				<ModalDownload
					modalOpen={modalDownload}
					closeModal = {()=>this.setModalDownload(false)}
					onSubmit={(values)=>downloadSummary(values)}
				/>

				<ModalImport
					modalOpen={modalImport}
					closeModal={()=>this.setModalImport(false)}
					onSubmit={(values)=>importXls(values)}
				/>
			</div>
		);
	}
}

export default TripList;
