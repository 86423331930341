import React, { Component } from 'react';
import classNames from "classnames";
import './js/jquery-3.4.1'
import './js/bootstrap-timepicker.js';
import './js/time-picker';
import "./css/time-picker.css";
import $ from './js/jquery-3.4.1';

class renderTimePicker extends Component {

    state = {
        isOpenWidget: false,
    }

    componentDidMount() {
        const timePicker = $(`#${this.props.id}`)
        timePicker.timepicker({
            minuteStep: 15,
            snapToStep: true,
            showInputs: false,
            disableFocus: false,
            showMeridian: true,
            defaultTime: '',
        })

        timePicker.on('changeTime.timepicker', (e) => this.setTimeOnState(e.time.value));

        timePicker.timepicker().on('show.timepicker', () => {
            this.setState({ isOpenWidget: true });
        })
        timePicker.timepicker().on('hide.timepicker', (e) => {
            this.setState({ isOpenWidget: false });
        });

        {this.props.Value && this.setTimeOnState(this.props.Value.format("LT A")) }
    }

    setTimeOnState = (timeString) => {
        const regexHour = /\d+:/
        const regexMinutes = /:[\d]{2}/

        let hour = timeString.match(regexHour)[0].slice(0, -1)
        const minutes = timeString.match(regexMinutes)[0].slice(1)
        const [, meridian] = timeString.split(' ')

        if (hour === '12' && meridian === 'PM') {
            //pass
        } else if (hour === '12' && meridian === 'AM') {
            hour = '0'
        } else if (meridian === 'PM') {
            hour = (+hour + 12).toString()
        }
        this.props.input.onChange({hour, minutes})
    }

    componentWillReceiveProps(nextProps){
        // if it is necessary to change the input value in real time
        if(this.props.Value && nextProps.Value && this.props.Value.format('LT') != nextProps.Value.format('LT'))
            $(`#${this.props.id}`).val(nextProps.Value.format('LT'))
    }

    showWidget = () => {
        if(!this.state.isOpenWidget){
            this.setState({ isOpenWidget: true });
            $(`#${this.props.id}`).timepicker('showWidget')
        }
    }


    render() {
        const {
            id, placeholder, style, className, disabled, type, meta: { touched, error },
        } = this.props

        const invalid = touched && error;

        return (
            <div className={`d-flex input-group bootstrap-timepicker timepicker`}>
                <input
                    id={id}
                    style={style}
                    defaultValue={this.props.Value ? this.props.Value.format("LT A") : ""}
                    placeholder={placeholder}
                    disabled={disabled}
                    autoComplete="off"
                    onClick={this.showWidget}
                    className={classNames(`form-control time-picker-input ${className?className:''}`, {'is-invalid': invalid})}
                />
                <span className="d-flex justify-content-center align-items-center input-group-addon" style={{width: "10%"}}>
                    <i className="glyphicon glyphicon-time" />
                </span>
                {invalid && (
                    <div className="invalid-feedback">
                        {invalid}
                    </div>
                )}

            </div>
        )
    }
}

export default renderTimePicker;
