import React from 'react';
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { customStylesModal } from '../../../../utility/constants';

class ModalDetail extends React.Component {

    constructor(props){
        super(props);
        this.textArea = React.createRef();
    }

    state = {
        showNotes: false,
    }

    setShowNotes(value) {
        this.setState({ showNotes: value })
	}

    render(){

        const { tripLoader, retrievedTrip, showModal, closeModal, tripUpdateBillingNotes, paidAmount } = this.props;
        const { showNotes } = this.state;

        return (
            <Modal
                isOpen={showModal}
                ariaHideApp={false}
                onRequestClose={() => {}}
                style={customStylesModal}
            >
                {
                    tripLoader ? (
                    <div className="d-flex flex-column justify-content-center"
                        style={{ height: '100%', padding: '10px 20px 0 20px' }}
                    >
                        <h2 className="mb-4"
                            style={{ textAlign: "center" }}
                        >
                            Loading...
                        </h2>
                    </div>
                    ): retrievedTrip ? (
                    <div
                        className="d-flex flex-column justify-content-between"
                        style={{ height: '100%', padding: '10px 20px 0 20px' }}
                    >
                        <h3 className="mb-3"
                            style={{ textDecoration: "underline" }}
                        >
                            { retrievedTrip && retrievedTrip.trip_cancelled ? "Trip ID: " + retrievedTrip.trip_order_number +  " (Canceled)" : "Trip ID: " + retrievedTrip.trip_order_number }
                        </h3>

                        <div className="mb-2 d-flex">
                            <div className="mr-3" style={{ fontWeight: "bold" }}>WMATA ID:</div>
                            <div>{retrievedTrip.user}</div>
                        </div>

                        <div className="mb-2 d-flex">
                            <div className="mr-3" style={{ fontWeight: "bold" }}>Full name:</div>
                            <div>{retrievedTrip.fullname}</div>
                        </div>

                        <div className="mb-2 d-flex">
                            <div className="mr-3" style={{ fontWeight: "bold" }}>Pick-up location:</div>
                            <div>{retrievedTrip.trip_pick_up_location}</div>
                        </div>

                        <div className="mb-2 d-flex" >
                            <div className="mr-3" style={{ fontWeight: "bold" }}>Drop-off location:</div>
                            <div>{retrievedTrip.trip_drop_off_location}</div>
                        </div>

                        <div className="mb-2 d-flex" >
                            <div className="mr-3" style={{ fontWeight: "bold" }}>Charges:</div>
                            <div>$ {retrievedTrip.trip_regular_price}</div>
                        </div>

                        <div className="mb-2 d-flex">
                            <div className="mr-3" style={{ fontWeight: "bold" }}>Subsidy:</div>
                            <div>$ {retrievedTrip.trip_wmata_subsidy}</div>
                        </div>

                        <div className="mb-2 d-flex">
                            <div className="mr-3" style={{ fontWeight: "bold" }}>Paid amount:</div>
                            <div>$ {paidAmount(retrievedTrip.trip_transactions)}</div>
                        </div>

                        {retrievedTrip.card_name && (
                            <div className="mb-2 d-flex">
                                <div className="mr-3" style={{ fontWeight: "bold" }}>Card used:</div>
                                <div>{retrievedTrip.card_name.replace(/([A-Z])/g, " $1").replace(/^\s+/g, "")}</div>
                            </div>
                        )}

                        {retrievedTrip.special_notes && (
                            <div className="mb-2 d-flex">
                                <div className="mr-3" style={{ fontWeight: "bold" }}>Special Notes:</div>
                                <div>{retrievedTrip.special_notes}</div>
                            </div>
                        )}

                        <div className="mb-2 d-flex">
                            <div className="mr-3" style={{ fontWeight: "bold" }}>Current Pick-up Date/Time:</div>
                            <div>{retrievedTrip ? " " + moment(retrievedTrip.trip_pick_up_date, "YYYY-MM-DDTHH:mm:ss").format("LLL") : ""}</div>
                        </div>

                        {retrievedTrip.trip_notes ? (
                            <div className="mb-2 d-flex">
                                <div className="mr-3" style={{ fontWeight: "bold" }}>Billing Notes:</div>
                                <div>{retrievedTrip.trip_notes}</div>
                            </div>
                        ) : ( !retrievedTrip.trip_cancelled
                            ? <div className="mb-2 d-flex flex-column">
                                    <div className="mr-3" style={{ fontWeight: "bold", color: "#D50032", height: "fit-content" }}>Billing Notes:</div>
                                    <textarea
                                        ref={this.textArea}
                                        rows={3}
                                        className="form-control"
                                        onChange={(e)=>{
                                            const value = this.textArea.current && this.textArea.current.value;
                                            if(value.length > 0)
                                                this.setShowNotes(true);
                                            else
                                                this.setShowNotes(false);
                                        }}
                                    />
                                </div>
                            : ''
                        )}

                        <div className="d-flex justify-content-between mt-4">
                            <div className="d-flex">
                                {retrievedTrip.trip_notes
                                  ? <button type="button"
                                        className="btn btn-warning wmata-buttons-text"
                                        onClick={()=>{ tripUpdateBillingNotes(retrievedTrip, null, true) }}
                                    >
                                        Remove notes
                                    </button>
                                  : showNotes && <button
                                        type="button"
                                        onClick={() => {
                                            tripUpdateBillingNotes(retrievedTrip, this.textArea.current.value, false)
                                        }}
                                        className="btn btn-success wmata-buttons-text mr-2"
                                    >
                                        Update Billing Notes
                                    </button>
                                }
                            </div>
                            <div className="d-flex">
                                <button
                                    type="button"
                                    onClick={closeModal}
                                    className="btn btn-primary wmata-buttons-text"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>

                    ):(
                        <div
                        className="d-flex flex-column justify-content-center"
                        style={{ height: '100%', padding: '10px 20px 0 20px' }}
                    >
                        <h2 className="mb-3"
                            style={{ textAlign: "center" }}
                        >
                            Error
                        </h2>
                        <button
                            type="button"
                            onClick={closeModal}
                            className="btn btn-primary wmata-buttons-text"
                            style={{ marginRight:"25%", marginLeft: "25%" }}
                        >
                            Close
                        </button>
                    </div>
                    )
                }
            </Modal>
        )
    }
}

export default ModalDetail;