import React, { Component } from 'react';


class Home extends Component {
  render() {
    return (
      <div className="row d-flex justify-content-center align-items-center logo-div">
        {/* <img src={require('assets/img/connect_logo.png')} alt="Logo" className="pb-2 home-with-logo"/> */}
      </div>
    );
  }
}

export default Home;
