import { connect } from 'react-redux';
import { update } from '../../../../redux/modules/cuenta/profile';
import Profile from './Profile';


const ms2p = (state) => {
  return {
    me: state.login.me,
  };
};

const md2p = { update };

export default connect(ms2p, md2p)(Profile);
