import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from './validation';
import { renderField, renderNumber } from '../Utils/renderField/renderField';

const ProfileForm = (props) => {
  const {
    title,
    handleSubmit,
    changePassword,
  } = props;
  return (
    <form action="" onSubmit={handleSubmit} className="py-4">
      <h2>{title}</h2>
      <div className="mb-4 card card-small">
        <div className="border-bottom card-header">
          <h6 className="m-0">
            {
              changePassword ? (
                "New Account"
              ) : (
                "Account"
              )
            }
          </h6>
        </div>
        <div className="p-0 pt-3 d-flex flex-column flex-md-row">
          <div className="d-flex flex-column flex-1 mx-3">
            <div className="form-group has-feedback">
              <label htmlFor="username">Username</label>
              <Field
                type="text"
                name="username"
                placeholder="Username"
                component={renderField}
                className="form-control"
              />
            </div>
            <div className="form-group has-feedback">
              <label htmlFor="first_name">First Name</label>
              <Field
                type="text"
                name="first_name"
                component={renderField}
                placeholder="First Name"
                className="form-control"
              />
            </div>
            <div className="form-group has-feedback">
              <label htmlFor="last_name">Last Name</label>
              <Field
                type="text"
                name="last_name"
                component={renderField}
                placeholder="Last Name"
                className="form-control"
              />
            </div>
            <div className="form-group has-feedback">
              <label htmlFor="email">Email</label>
              <Field name="email" placeholder="Email" component={renderField} type="text" className="form-control" />
            </div>
            <div className="form-group has-feedback">
              <label htmlFor="profile.cell_phone">Cell Phone</label>
              <Field
                numberFormat={"### ### ####"}
                name="profile.cell_phone"
                placeholder="Cell Phone"
                component={renderNumber}
                className="form-control"
              />
            </div>
            {
              changePassword ? (
                <Fragment>
                  <div className="form-group has-feedback">
                    <label htmlFor="password">New Password</label>
                    <Field
                      type="text"
                      name="password"
                      component={renderField}
                      className="form-control"
                      placeholder="New password"
                    />
                  </div>
                  <div className="form-group has-feedback">
                    <label htmlFor="confirm_password">Confirm Password</label>
                    <Field
                      name="confirm_password"
                      placeholder="Confirm password"
                      component={renderField}
                      type="text"
                      lassName="form-control"
                    />
                  </div>
                </Fragment>
              ) : (
                <label className="change-password" onClick={() => showPasswordInputs(true)}>
                  Change Password
                </label>
              )
            }
          </div>
          <div className="form-group has-feedback flex-1 mx-3" />
        </div>
        <div className="d-flex">
          <button
            type="submit"
            className="btn btn-primary mx-auto mb-3"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'register', // a unique identifier for this form
  validate,
})(ProfileForm);
