import Swal from 'sweetalert2';
import { handleActions } from 'redux-actions';
import { NotificationManager } from 'react-notifications';

export const handleSubmit = () => (dispatch, getStore) => {
  const store = getStore();
  const notiForm = store.form.notificacioForm.values;
  switch (notiForm.typeNoti) {
  case 'info':
    NotificationManager.info(notiForm.mensaje, notiForm.titulo);
    break;
  case 'success':
    NotificationManager.success(notiForm.mensaje, notiForm.titulo);
    break;
  case 'warning':
    NotificationManager.warning(notiForm.mensaje, notiForm.titulo, 3000);
    break;
  case 'error':
    NotificationManager.error(notiForm.mensaje, notiForm.titulo, 5000);
    break;
  default:
    break;
  }
};

export const showSweet = () => (dispatch, getStore) => {
  const store = getStore();
  const notiForm = store.form.notificacioSweetForm.values;
  switch (notiForm.typeNoti) {
  case 'success':
    Swal.fire('Success!',
      'The process has been successful!',
      notiForm.typeNoti);
    break;
  case 'error':
    Swal.fire({
      type: notiForm.typeNoti,
      title: 'Uupssss',
      text: 'An error has occurred!',
    });
    break;
  case 'info':
    Swal.fire('Information!',
      'Some info here',
      notiForm.typeNoti);
    break;
  case 'warning':
    Swal.fire({
      title: 'Do you want to delete it?',
      text: "This action cannot be reversed!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!',
    }).then((result) => {
      if (result.value) {
        Swal.fire('Success!', 'The record has been deleted.', 'success');
      }
    });
    break;
  default:
    break;
  }
};

export const actions = {
  showSweet,
  handleSubmit,
};

export const reducers = {};

export const initialState = {};

export default handleActions(reducers, initialState);
