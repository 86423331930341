import React from 'react';
import Select, { Creatable, Async } from 'react-select';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from 'react-switch';
import DayPicker from '../DayPicker';
import FileUploader from '../FileUploader/FileUploader';
import isEmpty from "lodash/isEmpty";
import UnstyledFileUploader from "../UnstyledFileUploader/UnstyledFileUploader";
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Dropdown } from 'semantic-ui-react';
import "semantic-ui-css/semantic.min.css";
import DatePicker from "react-date-picker";
import moment from 'moment';
import _ from "lodash";


export const renderField = ({
                                input, placeholder, type, meta: { touched, error },
                            }) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                {...input}
                placeholder={placeholder}
                type={type}
                className={classNames('form-control', { 'is-invalid': invalid })}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderTextArea = ({
                                   input, placeholder, rows, meta: { touched, error },
                               }) => {
    const invalid = touched && error;
    return (
        <div>
      <textarea
          {...input}
          placeholder={placeholder}
          style={{ resize: 'none' }}
          rows={rows || 3}
          className={classNames('form-control', { 'is-invalid': invalid })}
      />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderNumber = ({
    input, decimalScale, placeholder, meta: { touched, error }, prefix="", suffix="", numberFormat,
    fixedDecimalScale = true, thousandSeparator = true
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale={fixedDecimalScale}
                value={input.value}
                thousandSeparator={thousandSeparator}
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrency = ({
                                   input, meta: { touched, error }, prefix="Q ", placeholder,
                               }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderSwitch = ({input, meta: { touched, error }, label, disabled, cb, className=""}) => {
    const invalid = touched && error;
    return (
        <div className={`d-flex align-items-center ${className}`}>
            <Switch
                onColor="#007bff"
                height={18}
                width={36}
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                    cb && cb(value)
                }}
                checked={input.value ? input.value : false}
                // id="normal-switch"
            />
            &nbsp;{label}
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderFieldCheck = ({ input, label, value, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderFieldRadio = ({ input, label, value, disabled, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="radio c-radio c-radio-nofont d-flex">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const SelectField = (
    {
        input,
        disabled,
        isClearable,
        isMulti,
        isSearchable,
        options,
        placeholder,
        labelKey="label",
        valueKey="value",
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({...option, label: option[labelKey], value: option[valueKey]});
    });
    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, {value});
    }

    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e[valueKey] : null); }}
                value={value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


export const AsyncSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        loadOptions,
        placeholder,
        meta: { touched, error }
    }) => {

    const invalid = touched && error;

    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const CreatableSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        options,
        placeholder,
        labelKey="label",
        valueKey="value",
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({...option, label: option[labelKey], value: option[valueKey]});
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const renderFilePicker = ({photo, setFile, className, disabled, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploader
                disabled={disabled}
                img= {!!photo ? photo : null}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};


export const renderUnstyledFilePicker = ({
    file,
    category,
    className = "",
    placeholder,
    input,
    label,
    accept = "",
    cb,
    defaultButton = true,
    classNameSubmit,
    classNameLabel,
    classNameContainer,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <UnstyledFileUploader
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange({ type: file.type, file: reader.result });
                    };

                    if(file)
                        reader.readAsDataURL(file);
                }}
                submit={cb}
                defaultButton={defaultButton}
                classNameSubmit={classNameSubmit}
                classNameLabel = {classNameLabel}
                classNameContainer={classNameContainer}
                accept={accept}
                label={label}
                invalid={invalid}
                error={error}
            />
            {invalid && defaultButton && <div className="invalid-feedback"> {error} </div>}
        </div>
    );
};

export const renderDayPicker = ({className, disabled, maxDate, minDate, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={e => input.onChange(e)}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderDatePicker = ({className, disabled, maxDate, minDate, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DatePicker
                onChange={e => input.onChange(e)}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderDropdownSelection = ({
    options=[],
    selection,
    fluid,
    className = "",
    input,
    multiple,
    clearable,
    scrolling,
    search,
    icon,
    cb,
    labeled,
    disabled,
    placeholder,
    meta: { touched, error },
    selectOnBlur=false,
    selectOnNavigation=false,
    wrapSelection=true,
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <Dropdown
                placeholder={placeholder}
                fluid={fluid}
                selection={selection}
                options={options}
                clearable={clearable}
                scrolling={scrolling}
                search={search}
                multiple={multiple}
                labeled={labeled}
                icon={icon}
                value={(input.value) || (multiple ? [] : "")}
                disabled={disabled}
                selectOnBlur={selectOnBlur}
                selectOnNavigation={selectOnNavigation}
                wrapSelection={wrapSelection}
                onChange={
                    (event,data)=>{
                        input.onChange(data.value);
                        cb && cb(data);
                    }
                }
            />
        {invalid && <div className="invalid-feedback"> {error} </div>}
        </div>
    )
}

export class renderDatePicker2 extends React.Component {
    state = {
        dpFocused: false,
    };

    onFocusChange = ({ focused }) => {
        this.setState({ dpFocused: focused });
    };

    render() {
        const {
            id,
            input,
            className,
            disabled,
            monthFormat,
            displayFormat,
            numberOfMonths,
            isOutsideRange = true,
            placeholder = moment().format('ddd, DD MMMM YYYY'),
            meta: { touched, error },
        } = this.props;
        const invalid = touched && error;

        return (
            <div >
                <div
                    className={classNames(`form-control ${className}`, {
                        'is-invalid': invalid,
                    })}
                >
                    <SingleDatePicker
                        placeholder={placeholder}
                        date={input.value ? moment(input.value) : null}
                        focused={this.state.dpFocused}
                        disabled={disabled}
                        onDateChange={value => {
                            input.onChange(value);
                        }}
                        monthFormat={monthFormat}
                        displayFormat={displayFormat}
                        isOutsideRange={isOutsideRange ? undefined : () => false }
                        isDayBlocked={!isOutsideRange ? undefined : day => {
                            let today = new Date();
                            today = new Date( today.getFullYear(), today.getMonth(), today.getDay() );
                            if (new Date(day) < today) return true;
                        }}
                        onFocusChange={this.onFocusChange}
                        numberOfMonths={numberOfMonths}
                        id={id ? id : 'unique'}
                    />
                </div>
                {invalid && <div className="invalid-feedback">{error}</div>}
            </div>
        );
    }
}

export class renderPlacesAutocomplete extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            address: '',
        };

        this.searchOptions = {
            componentRestrictions: { country: 'us' },
        };

        this.autoCompleteContainerRef = React.createRef();
    }

    componentDidMount() {
        const { Value, lat, lng } = this.props;

        this.geocoder = new google.maps.Geocoder();
        if (this.geocoder && lat && lng) this.verifyOnLoad(lat, lng);

        if(Value){
            let placeId = undefined;
            geocodeByAddress(Value).then(result => {
                placeId = result[0].place_id
                if(placeId) this.handleSelect(Value, placeId);
            })
        }
      }

    verifyOnLoad = (lat, lng) => {
        // gets place id
        this.geocoder.geocode({ location: { lat, lng } }, ([place], status) => {
            if (status != google.maps.places.PlacesServiceStatus.OK)
                throw Error(status);
        });
    }

    handleSelect = (address, placeId) => {
        this.geocode(placeId);
    }

    geocode = (placeId) => {
        const { setDetails, input, pl } = this.props;

        this.geocoder.geocode( { placeId, },
            ([place], status) => {
                if (status != google.maps.places.PlacesServiceStatus.OK) return;

                const { address_components, geometry: { location }, formatted_address, types, } = place;
                const lat = location.lat(), lng = location.lng();

                let street_number = {},
                    route = {},
                    locality = {},
                    administrative_area_level_1 = {},
                    postal_code = {};

                address_components.forEach(component => {
                    if (component.types.includes('street_number'))
                        street_number = component;
                    else if (component.types.includes('route'))
                        route = component;
                    else if ( isEmpty(locality) && (component.types.includes('locality') || component.types.includes('sublocality')) )
                        locality = component;
                    else if ( component.types.includes('administrative_area_level_1') )
                        administrative_area_level_1 = component;
                    else if (component.types.includes('postal_code'))
                        postal_code = component;
                });

                const short_str_no = street_number.short_name || '';
                const short_route = route.short_name || '';
                const short_locality = locality.short_name || '';
                const short_admin_area_lv_1 = administrative_area_level_1.short_name || '';
                const pretty_address = `${short_str_no} ${short_route}, ${short_locality}, ${short_admin_area_lv_1}`;

                const street = short_str_no + " " + short_route

                if(setDetails){
                    setDetails({
                        lat,
                        lng,
                        state: short_admin_area_lv_1,
                        street,
                        city: locality.long_name || '',
                        postal_code: postal_code.short_name,
                        formatted_address,
                        types,
                        pretty_address,
                    });
                }
                this.setState({ address: formatted_address }, () => {
                    input.onChange(formatted_address);
                });
            }
        );
    }

    render() {
        const {
            input,
            className,
            label,
            disabled,
            placeholder,
            meta: { touched, error },
        } = this.props;
        const invalid = touched && error;

        return (
            <div className={classNames(`w-100 p-0 input-highlight`, { 'is-invalid': invalid, })}>
                <PlacesAutocomplete
                    value={this.state.address}
                    onChange={value => {
                        this.setState({ address: value }, () => {
                            input.onChange(value);
                        });
                    }}
                    onSelect={this.handleSelect}
                    searchOptions={this.searchOptions}
                >
                    {({
                        getInputProps,
                        getSuggestionItemProps,
                        suggestions,
                        loading,
                    }) => (
                        <div ref={this.autoCompleteContainerRef} style={{ height: "calc(1.5em + 0.75rem + 2px)" }}>
                            <input
                                {...getInputProps({
                                    placeholder,
                                    className: classNames(`input-reset pl-2 mb-1 w-100 form-control`, { 'is-invalid': invalid, }),
                                    onBlur: () => {
                                        this.autoCompleteContainerRef.current.className = '';
                                        if (!suggestions.length) return;
                                        const [{ placeId }] = suggestions;
                                        this.geocode(placeId);
                                    },
                                    onFocus: () => {
                                        this.autoCompleteContainerRef.current.className = 'autocomplete-container';
                                    },
                                    disabled,
                                })}
                            />
                            {loading && (
                                <div className="autocomplete-dropdown-container">
                                    <div>Loading...</div>
                                </div>
                            )}
                            {suggestions.length > 0 && (
                                <div className="autocomplete-dropdown-container">
                                    {suggestions.map(suggestion => (
                                        <div
                                            className="p-1 select-option"
                                            {...getSuggestionItemProps(
                                                suggestion
                                            )}
                                            key={suggestion.index}
                                        >
                                            <span> {suggestion.description} </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </PlacesAutocomplete>
                {invalid && (
                    <div className="invalid-feedback"> {error} </div>
                )}
            </div>
        );
    }
}

export const RenderField = {
    renderField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderFieldCheck,
    renderFieldRadio,
};
