import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import { Login, Profile, Registro } from './common/components/LoginRegister';
// import Demo from './common/components/Demo/Demo';
import Home from './common/components/Home/Home';
import ProtectedRoute from './ProtectedRoute';

// Admin Accounts

import AdminAccounts from './common/components/AdminsAccounts';
import AdminAccountsDetail from './common/components/AdminsAccounts/AdminAccountsDetailContainer';
import AdminForm from "./common/components/AdminsAccounts/AdminFormContainer";

// WMATA Accounts

import PendingAccounts from './common/components/WMATA/PendingAccounts';
import PendingDetail from './common/components/WMATA/PendingAccounts/PendingDetailContainer';
import ApprovedAccounts from './common/components/WMATA/ApprovedAccounts';
import ApprovedDetail from './common/components/WMATA/ApprovedAccounts/ApprovedDetailContainer';
import DeniedAccounts from './common/components/WMATA/DeniedAccounts';
import DeniedDetail from './common/components/WMATA/DeniedAccounts/DeniedDetailContainer';
import TripReport from './common/components/Reports/TripReportsContainer';
import SuccessLoginOauth from "./successLoginOauthPage";
import PublicRoute from "./PublicRoute";
import {
  TripList
} from './common/components/Trips';
import NotFound from './common/components/layout/NotFound/NotFound';

import '../assets/fonts/fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import WMATAResetPassword from "./common/components/WMATA/ResetPassword";

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');

require('../style/index.css');

module.exports = (
  <div>
    <div className="container__content">
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/wmata-user/reset_password/:token" component={WMATAResetPassword} />
        <Route exact path="/registro" component={Registro} />
        <ProtectedRoute exact path="/" component={Home} />
        <ProtectedRoute exact path="/trips" component={TripList} />
        <ProtectedRoute exact path="/admin-accounts" component={AdminAccounts} />
        <ProtectedRoute exact path="/admin-accounts/create" component={AdminForm} />
        <ProtectedRoute exact path="/admin-accounts/:id/detail" component={AdminAccountsDetail} />
        <ProtectedRoute exact path="/pending-accounts" component={PendingAccounts} />
        <ProtectedRoute exact path="/pending-accounts/:id/detail" component={PendingDetail} />
        <ProtectedRoute exact path="/approved-accounts" component={ApprovedAccounts} />
        <ProtectedRoute exact path="/approved-accounts/:id/detail" component={ApprovedDetail} />
        <ProtectedRoute exact path="/denied-accounts" component={DeniedAccounts} />
        <ProtectedRoute exact path="/denied-accounts/:id/detail" component={DeniedDetail} />
        <ProtectedRoute exact path="/trip-reports" component={TripReport} />
        <ProtectedRoute exact path="/user-profile" component={Profile} />
        <PublicRoute exact path="/success/:token" component={SuccessLoginOauth}/>
        <Route component={NotFound} />
      </Switch>
    </div>
    <NotificationContainer />
  </div>
);
