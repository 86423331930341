import { connect } from 'react-redux';
import { list_pending_accounts } from '../../../../redux/modules/WMATA/accounts/accounts';
import Pendings from './Pendings';


const ms2p = (state) => {
  return {
    data: state.wmata_accounts.data,
    page: state.wmata_accounts.page,
    loader: state.wmata_accounts.loader,
  };
};

const md2p = { list_pending_accounts };

export default connect(ms2p, md2p)(Pendings);
