import { connect } from 'react-redux';
import { approve, detail } from '../../../../redux/modules/WMATA/accounts/accounts';
import DeniedDetail from './DeniedDetail';


const ms2p = (state) => {
  return {
    loader: state.wmata_accounts.loader,
    updateData: state.wmata_accounts.updateData,
  };
};

const md2p = { approve, detail };

export default connect(ms2p, md2p)(DeniedDetail);
