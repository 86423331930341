import React, { Component } from 'react';
import PropTypes from "prop-types";
import ProfileForm from "./ProfileForm";


class Profile extends Component {
  static propTypes = {
    me: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      avatar: null,
      change_password: false,
    };
  }

  showPasswordInputs = (change_password) => {
    this.setState({ change_password });
  }

  setAvatar = (avatar) => {
    this.setState({ avatar });
  };

  update = (data) => {
    const { update } = this.props;
    this.setState({ change_password: false }, () => update({ ...data, avatar: null }, [{"file": this.state.avatar, "name": "avatar"}]));
  };

  render() {
    const { me } = this.props;

    return (
      <ProfileForm
        me={me}
        initialValues={me}
        onSubmit={this.update}
        setAvatar={this.setAvatar}
        showPasswordInputs={this.showPasswordInputs}
        changePassword={this.state.change_password}
      />
    );
  }
}

export default Profile;
