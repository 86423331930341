import { connect } from 'react-redux';
import { listar, destroy } from '../../../redux/modules/adminAccounts/adminAccounts';
import AdminAccounts from './AdminAccounts';


const ms2p = (state) => {
  return {
    data: state.admins.data,
    page: state.admins.page,
    loader: state.admins.loader,
  };
};

const md2p = { destroy, listar };

export default connect(ms2p, md2p)(AdminAccounts);
