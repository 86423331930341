import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './acciones.css';
import Swal from 'sweetalert2';


class Acciones extends Component {

    destroy = (id) => {
        return () => {
            Swal.fire({
                title: 'Delete?',
                text: 'You cannot reverse this action!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Yes, delete!',
                cancelButtonText: 'No, cancel.',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.erase(id);
                }
            });
        }
    };

    render() {
        const { id, detail, edit, erase } = this.props;

        return (
            <div className="d-flex justify-content-center">
                {(edit !== undefined) && (
                    <Link className="text-warning px-1" to={`${edit}/${id}/edit`} ><i className="material-icons">edit</i></Link>
                )}
                {(detail !== undefined) && (
                    <Link to={`${detail}/${id}/detail`} className="px-1" ><i className="material-icons" style={{cursor: "pointer", color: "#007bff"}}>visibility</i></Link>
                )}
                {(erase !== undefined) && (
                    <a className="px-1" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.destroy(id)}><i className="material-icons">delete</i></a>
                )}
            </div>
        );
    }
}
Acciones.propTypes = {
};

export function standardActions(acciones) {
    return (cell, row) => {
        return ( <Acciones id={cell} {...acciones}/> )
    };
}
