import { connect } from 'react-redux';
import { deny, detail } from '../../../../redux/modules/WMATA/accounts/accounts';
import ApprovedDetail from './ApprovedDetail';


const ms2p = (state) => {
  return {
    loader: state.wmata_accounts.loader,
    updateData: state.wmata_accounts.updateData,
  };
};

const md2p = { deny, detail };

export default connect(ms2p, md2p)(ApprovedDetail);
