import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';

const UserForm = (props) => {
  const { handleSubmit } = props;
  return (
    <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
      <div className="form-group has-feedback">
        <label htmlFor="username">User</label>
        <Field
          name="username"
          label="Username"
          component={renderField}
          type="text"
          className="form-control"
        />
      </div>
      <div className="buttons-box">
        <button type="submit" className="btn btn-primary m-1 align-self-center">Next</button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'loginUser', // a unique identifier for this form
  destroyOnUnmount: false,
  validate: (data) => {
    return validate(data, {
      username: validators.exists()('This field is required'),
    });
  },
})(UserForm);
