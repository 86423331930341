import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';
import PropTypes from "prop-types";
import Form from './Form';

export default class AdminForm extends Component {
  static propTypes = {
    loader: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  render() {
    const {
      loader,
      onSubmit: create,
    } = this.props;

    return (
      <div className="content">
        <div className="m-auto row-fluid card-form">
          <div className="table-decoration">
            <LoadMask loading={loader} blur_1>
              <div className="fields">
                <Form
                  changePassword
                  onSubmit={create}
                  title="CREATE AN ADMIN ACCOUNT"
                />
              </div>
            </LoadMask>
          </div>
        </div>
      </div>
    );
  }
}
