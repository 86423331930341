import { connect } from 'react-redux';
import { detail } from '../../../redux/modules/adminAccounts/adminAccounts';
import AdminAccountsDetail from './AdminAccountsDetail';

const ms2p = (state) => {
  return {
    loader: state.admins.loader,
    updateData: state.admins.updateData,
  };
};

const md2p = { detail };

export default connect(ms2p, md2p)(AdminAccountsDetail);
