import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import moment from 'moment';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { renderFilePicker, renderUnstyledFilePicker } from '../../Utils/renderField/renderField';

const Form = (props) => {

    const { handleSubmit, onSubmit } = props;

    return (
        <form onSubmit={async (document) => handleSubmit(document)}>
            <div className="d-flex flex-row flex-wrap justify-content-center">
                <div className="col-12 col-md-6">
                    <Field
                        name="file"
                        component={renderUnstyledFilePicker}
                        classNameLabel="mw-100 w-100 mb-4"
                        classNameSubmit="btn btn-primary btn-block"
                        defaultButton={false}
                        accept = ".xls, .xlsx"
                        className="w-100"
                        label="Select a file"
                    />
                </div>
            </div>
        </form>
    )
}

const FileUploadForm = reduxForm({
    form: "importXls",
    validate: (data) => {
        const errors = {}
        if(!data.file) errors.file = "Required field"
        return errors;
    }
})(Form)


class ModalImportXls extends React.Component {


    render(){

        const { modalOpen, closeModal, loading, onSubmit } = this.props;

        return (
            <Modal isOpen={modalOpen} toggle={closeModal} zIndex={115000} className="modal-lg">
                <ModalBody>

                    <div className="d-flex flex-column">
                        <div className="d-flex">
                            <div className="flex-1 text-center">
                                <h3> Import file </h3>
                            </div>
                            <i className="clickable" onClick={closeModal}>
                                <span className="material-icons text-dark">close</span>
                            </i>
                        </div>
                        <hr className="w-100"/>
                        <LoadMask loading={loading} blur>
                            <div className="py-5">
                                <FileUploadForm
                                    onSubmit={onSubmit}
                                />
                            </div>
                        </LoadMask>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

const mstp = (state) => ({
    loading: state.trips.loader_import
});

export default connect(mstp, null)(ModalImportXls);
