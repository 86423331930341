import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LoadMask from "Utils/LoadMask/LoadMask";
import UserForm from './UserForm';
import PasswordForm from './PasswordForm';
import './login.css';
const { BACKEND_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/': 'https://wmata-api.azurewebsites.net/' } = process.env;

class Login extends Component {
  static propTypes = {
    loader: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    step: 1,
  }

  nextStep = (values) => {
    if(values && values.username && values.username.match(/@connectbbc.com|@bbcexpress.com/g) !== null){
      location.href = `${BACKEND_URL}oauth2/login`;
    } else {
        this.setState({ step: this.state.step + 1 });
    }
  }
  previusStep = () => this.setState({ step: this.state.step - 1 })

  render() {
    const { onSubmit, loader } = this.props;
    if (localStorage.getItem('token')) {
      return (<Redirect to="/" />);
    }
    return (
      <div className="d-flex flex-column all-screen justify-content-center">
        <div className="login-wrapper">
          <div className="card card-login col-lg-3 col-md-4 col-11">
            <img
              src={require('assets/img/connect_logo.png')}
              alt="Logo"
              className="login-logo"
            />
            <h5 className="text-center mt-3">WMATA&nbsp;Log in</h5>
            <LoadMask loading={loader} light>
              {this.state.step === 1 && (
                <UserForm
                  onSubmit={this.nextStep}
                />
              )}
              {this.state.step === 2 && (
                <PasswordForm
                  onSubmit={onSubmit}
                  previusStep={this.previusStep}
                />
              )}
            </LoadMask>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
