import { connect } from 'react-redux';
import { list_approved_accounts } from '../../../../redux/modules/WMATA/accounts/accounts';
import Approved from './Approved';


const ms2p = (state) => {
  return {
    data: state.wmata_accounts.data,
    page: state.wmata_accounts.page,
    loader: state.wmata_accounts.loader,
  };
};

const md2p = { list_approved_accounts };

export default connect(ms2p, md2p)(Approved);
